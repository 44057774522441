import React, { useState, useEffect } from 'react'
import { axios } from '../../config'
import M from 'materialize-css'

const OrderStatus = () => {

	const [orders, setOrders]=useState({data:[], links:[]})
	const [pedido, setPedido]=useState({articulos:[], sucursal:{nombre:''}})


	useEffect(()=>{
		axios.get('/api/orderStatus')
		.then(r=>{
			setOrders(r.data)
			M.Modal.init(document.querySelectorAll('.modal'), {});
		})
		.catch(r=>alert(r))
	},[])

	const _cancelar = () => {
		axios.post('/api/cancelarPedido',pedido)
		.then(r=>{
			if(r.status===202){
				const pedidos=orders.map(o=>o.id===pedido.id?{...o,status:'cancelado'}:o)
				setOrders(pedidos)
				setPedido({...pedido, status:'cancelado'})
			}
			alert(r.data.status)
		})
		.catch(r=>alert(r))
	}

	const _getPage = (url) => {
		if(url)
			axios.get(url)
			.then(r=>{
				setOrders(r.data)
				M.Modal.init(document.querySelectorAll('.modal'), {});
			})
			.catch(r=>alert(r))
	}

	return (
		<div className="card-panel">
			<div className="row">
				<div id="modal1" className="modal modal-fixed-footer full" style={{width: '100vw',height: '100vh'}}>
					<div className="modal-content">
						<h4>{pedido.sucursal.nombre}</h4>
						<p>{pedido.descripcion}</p>
						progreso {pedido.articulos.reduce((a,b)=>a+=b.piezasEntregadas+b.piezasEnCarrito,0)*100/pedido.articulos.reduce((a,b)=>a+=b.piezas,0)+'%'}
						<div className="progress">
							<div className="determinate" style={{width: pedido.articulos.reduce((a,b)=>a+=b.displayEntregados+b.carrito,0)*100/pedido.articulos.reduce((a,b)=>a+=b.display,0)+'%'}}></div>
						</div>
						<div className="row">
							<div className="col">Status: {pedido.status}</div>
							<div className="col">Usuario Responsable: {pedido.user?pedido.user.name:''}</div>
						</div>
						<div className="table-wrapper">
							<table className="highlight">
								<thead>
									<tr>
										<th>#</th>
										<th>Código</th>
										<th>Nombre</th>
										<th>Departamento</th>
										<th>Categoria</th>
										<th>Piezas</th>
										<th>Piezas por Display</th>
										<th>Piezas Entregadas</th>
										<th>Piezas En Carrito</th>
									</tr>
								</thead>
								<tbody>
								{pedido.articulos.map((a,i)=>
									<tr key={i}>
										<td>{i+1}</td>
										<td>{a.codigo}</td>
										<td>{a.nombre}</td>
										<td>{a.departamento}</td>
										<td>{a.categoria}</td>
										<td>{a.piezas}</td>
										<td>{a.piezasPorDisplay}</td>
										<td>{a.piezasEntregadas}</td>
										<td>{a.piezasEnCarrito}</td>
									</tr>
								)}
								</tbody>
							</table>
						</div>
					</div>
					<div className="modal-footer">
						<button className="btn waves-effect waves-light left red" onClick={_cancelar}>CANCELAR PEDIDO</button>
						<a href="#!" className="modal-close waves-effect waves-green btn-flat black white-text">Cerrar</a>
					</div>
				</div>
				<div className="table-wrapper">
					<table className="highlight">
						<thead>
							<tr>
								<th>#Pedido</th>
								<th>Sucursal</th>
								<th>Descripción</th>
								<th>Status</th>
								<th>Colaborador</th>
								<th>status</th>
							</tr>
						</thead>
						<tbody>
						{orders.data.map((o,i)=>
							<tr key={i}>
								<td>{o.id}</td>
								<td>{o.sucursal.nombre}</td>
								<td>{o.descripcion}</td>
								<td>{o.status}</td>
								<td>{o.user?o.user.name:'No ha sido tomado'}</td>
								<td><a className="waves-effect waves-light btn modal-trigger" href="#modal1" onClick={()=>setPedido(o)}>Ver Status</a></td>
							</tr>
						)}
						</tbody>
					</table>
				</div>
				<ul className="pagination right">
					<li className="waves-effect"><a href="#!" onClick={()=>_getPage(orders.prev_page_url)}><i className="material-icons">chevron_left</i></a></li>

					{orders.links.slice(1,-1).map((l,i) =>
						<li key={i} className={l.active?'waves-effect active':'waves-effect'}><a href="#!" onClick={()=>_getPage(l.url)}>{l.label}</a></li>
					)
					}

					<li className="waves-effect"><a href="#!" onClick={()=>_getPage(orders.next_page_url)}><i className="material-icons">chevron_right</i></a></li>
				</ul>
			</div>
		</div>
	)
}
export default OrderStatus
