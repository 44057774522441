import React, { useState, useEffect } from 'react'
import { axios } from '../../config'

const StatusPacking = () => {
	const [containers, setContainers]=useState([])

	useEffect(()=>{
		axios.get('/api/containerStatus')
		.then(r=>{
			setContainers(r.data)
		})
		.catch(r=>alert(r))
	},[])

	return (
			<div className="row">
				<div className="card-panel">
				<h4>Contenedores</h4>
				<table className="highlight">
					<thead>
						<tr>
							<th>#</th>
							<th>contenedor</th>
							<th>Status</th>
							<th>fotos</th>
						</tr>
					</thead>
					<tbody>
					{containers.map((c,i)=>
						<tr key={i}>
							<td>{i+1}</td>
							<td>{c.contenedor}</td>
							<td>{c.status}</td>
							<td>
							{c.fotos.map((f,i)=>
								<div key={i}>
									<a href={axios.defaults.baseURL+'/contenedores/'+c.contenedor+'/'+f.id+'.jpg'} target='_blanck'>{f.created_at}</a><br />
								</div>
							)}
							</td>
						</tr>
					)}
					</tbody>
				</table>
				</div>
			</div>
	)
}

export default StatusPacking
