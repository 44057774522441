import React, { useState, useEffect } from 'react'
import { axios } from '../../config'

const StockRacks = () => {

	const [inspeccion, setInspeccion]=useState([])
	const [search, setSearch]=useState([])

	useEffect(()=>{
		axios.get('/api/getInspection')
		.then(r=>{
			setInspeccion(r.data)
                 })
                 .catch(r=>alert(r))
	},[])
	
	const _rackToExcel = () => {
		const busqueda=inspeccion.filter(s=>s.caja_id.includes(search)).map(b=>b.caja_id)
		axios.post('/api/evaluateToExcel',{cajas:busqueda})
		.then(r=>{
			var a = document.createElement("a");
			a.href = r.data.file;
			a.download = r.data.name;
			document.body.appendChild(a);
			a.click();
			a.remove();
		})
		.catch(r=>alert(r))
	}

	return (
			<div className="row">
				<div className="card-panel">
				<h4>Por Evaluar</h4>
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field">
								<input value={search} onChange={e=>setSearch(e.target.value)} id="search" type="search" required />
								<label className="label-icon" htmlFor="search"><i className="material-icons">search</i></label>
								<i className="material-icons">close</i>
							</div>
						</form>
					</div>
				</nav>
				<a href="#!" className="waves-effect waves-light btn right" onClick={_rackToExcel}>Exportar</a>
				<table className="highlight">
					<thead>
						<tr>
							<th>#</th>
							<th>Caja</th>
							<th>Contenido</th>
							<th>Productos</th>
						</tr>
					</thead>
					<tbody>
					{inspeccion.filter(i=>i.caja_id.includes(search)).map((m,i)=>
						<tr key={i}>
							<td>{i+1}</td>
							<td>{m.caja_id}</td>
							<td>{m.productos.map((p,i)=><div key={i}>{p.codigo}	{p.nombre_del_producto}</div>)}</td>
							<td>{m.total}</td>
						</tr>
					)}
					</tbody>
				</table>
				</div>
			</div>
	)
}
export default StockRacks
