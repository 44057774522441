import React, { useState, useEffect } from 'react';
import { axios } from '../../../config'
import M from 'materialize-css'

const Step3CompanyCompletedInfo = ({percent_calc}) => {

	const [data, setData] = useState({company_year_registered:'',company_total_employees:'',company_website:'',company_legal_owner:'',company_office_size:'',company_advantages:'',company_accepted_delivery_terms:[], company_accepted_payment_type:[], company_logo:'',company_introduction:'', company_photos:[]})
	const company_accepted_delivery_terms = ['EXW', 'FCA', 'FAS', 'FOB', 'CFR', 'CIF', 'CPT', 'CIP', 'DAP', 'DPU', 'DDP'].sort()
	const company_accepted_payment_type = ['T/T', 'L/C', 'D/P D/A', 'Money Gram', 'Credit Card', 'PayPal', 'Escrow', 'Asiamart'].sort()

	useEffect(()=>{
		axios.get('/api/get_seller')
		.then(r=>{
			setData(r.data)
			M.FormSelect.init(document.querySelectorAll('select'), {});
			M.updateTextFields()
			M.Materialbox.init(document.querySelectorAll('.materialboxed'), {});

		})
	},[])

	const _send = (e) => {
		e.preventDefault()
		console.log(data)
		if(data.company_advantages===null||data.company_advantages?.length<256){
			alert('Company Advantages min 256 chars')
			return 0
		}

		if(data.company_introduction===null||data.company_introduction?.length<500){
			alert('Company Introduction min 500 chars')
			return 0
		}

		let company_logo = document.getElementById('company_logo')?.files[0]
		const formData = new FormData();
		formData.append('data', JSON.stringify(data))
		formData.append('company_logo', company_logo)
		console.log(formData)
		axios.post('/api/seller_complete_info', formData)
		.then(r=>{
			console.log(r.data)
			setData(r.data)
			percent_calc(r.data.states)
			M.Materialbox.init(document.querySelectorAll('.materialboxed'), {});
			M.toast({html:'Updated!'})
			/**
			  * Next tab
			  */
			const tabs = document.querySelector(".tabs")
			const instance = M.Tabs.getInstance(tabs)
			instance.select('step4')
			window.scrollTo(0, 0)
		})
	}

	const _update_company_accepted_delivery_terms = (e, value) =>{
		const aux = [...data.company_accepted_delivery_terms]
		if(e.target.checked){
			setData({...data, company_accepted_delivery_terms:aux.includes(value)?aux:[...aux, value].sort()})
		}else{
			aux.splice(aux.indexOf(value), 1)
			const newData = {...data, company_accepted_delivery_terms:aux}
			setData(newData)
		}
	}

	const _update_company_accepted_payment_type = (e, value) =>{
		const aux = [...data.company_accepted_payment_type]
		if(e.target.checked){
			setData({...data, company_accepted_payment_type:aux.includes(value)?aux:[...aux, value].sort()})
		}else{
			aux.splice(aux.indexOf(value), 1)
			const newData = {...data, company_accepted_payment_type:aux}
			setData(newData)
		}
	}

	const _imgDestroya = (img) => {
		axios.post('/api/delete_company_photos', {url:img})
		.then(r=>{
			console.log(r.data)
			setData({...data, company_photos:r.data.company_photos})
			M.Materialbox.init(document.querySelectorAll('.materialboxed'), {});
		})
	}

	const _fileUpload = () => {

		let company_photos = document.getElementById('company_photos').files[0]

		const formData = new FormData();
		formData.append('company_photos', company_photos)

		axios.post('/api/company_photos', formData)
		.then(r=>{
			console.log(r.data)
			setData({...data, company_photos:r.data.company_photos})
			M.Materialbox.init(document.querySelectorAll('.materialboxed'), {});
			//percent_calc(r.data.states)
		})
	}

	return (
		<form onSubmit={_send}>
			<div className="row">
				<div className="col s12" style={{background:'grey', color:'white'}}>
					<div>Company complete info</div>
				</div>
			</div>

			<div className="row">
				<div className="input-field col s12">
					<select value={data.company_year_registered||''} onChange={(e)=>setData({...data, company_year_registered:e.target.value})}>
						<option value="" disabled>Choose your option</option>
						{Array.from({ length: 51 }, (_, i) => new Date().getFullYear() - i).map((anio) => (
						<option key={anio} value={anio}>{anio}</option>
						))}
					</select>
					<label>Year Company Registered</label>
				</div>
				<div className="input-field col s12">
					<select value={data.company_total_employees||''} onChange={(e)=>setData({...data, company_total_employees:e.target.value})}>
						<option value="" disabled>Choose your option</option>
						{['Fewer than 5 People', '5 - 10 People', '11 - 50 People', '51 - 100 People', '101 - 200 People', '201 - 300 People', '301 - 500 People', '501 - 1000 People', 'Above 1000 People'].map((e,i)=>
							<option key={i} value={e}>{e}</option>
						)}
					</select>
					<label>Total No. Employees</label>
				</div>
				<div className="input-field col s12">
					<input value={data.company_website||''} onChange={(e)=>setData({...data, company_website:e.target.value})} id="company_website" type="text" className="validate" placeholder='https://'/>
					<label htmlFor="company_website">Company Website Url</label>
				</div>
				<div className="input-field col s12">
					<input value={data.company_legal_owner||''} onChange={(e)=>setData({...data, company_legal_owner:e.target.value})} id="company_legal_owner" type="text" className="validate" placeholder='Full Name'/>
					<label htmlFor="company_legal_owner">Legal Owner</label>
				</div>
				<div className="input-field col s12">
					<select value={data.company_office_size||''} onChange={(e)=>setData({...data, company_office_size:e.target.value})}>
						<option value="" disabled>Choose your option</option>
						{['Below 100 square meters', '101 - 500 Square meters', '501 - 1000 Square meters', '1001 - 2000 Square meters', 'Above 2000 Square meters'].map((e,i)=>
							<option key={i} value={e}>{e}</option>
						)}
					</select>
					<label>Office Size</label>
				</div>
				<div className="input-field col s12">
					<textarea value={data.company_advantages||''} onChange={(e)=>setData({...data, company_advantages:e.target.value})} id="company_advantages" className="materialize-textarea" style={{backgroundColor:'#f9f9f9', height:100, border:1, borderColor:'black'}}></textarea>
					<label htmlFor="company_advantages">Company Advantages</label>
					<div className={(data.company_advantages===null||data.company_advantages?.length<256)?'right red-text':'right green-text'}>{data.company_advantages?.length}/256 chars min</div>
				</div>

				<div className="col s12" style={{background:'grey', color:'white'}}>
					<div>Accepted Delivery Terms</div>
				</div>
				{company_accepted_delivery_terms.map((business, i)=>
				<div key={i} className="input-field col">
					<p>
						<label>
							<input type="checkbox" checked={data.company_accepted_delivery_terms.includes(business)} onChange={(e)=>_update_company_accepted_delivery_terms(e, business)}/>
							<span>{business}</span>
						</label>
					</p>
				</div>
				)}

				<div className="col s12" style={{background:'grey', color:'white'}}>
					<div>Accepted Payment Type</div>
				</div>

				{company_accepted_payment_type.map((business, i)=>
				<div key={i} className="input-field col">
					<p>
						<label>
							<input type="checkbox" checked={data.company_accepted_payment_type.includes(business)} onChange={(e)=>_update_company_accepted_payment_type(e, business)}/>
							<span>{business}</span>
						</label>
					</p>
				</div>
				)}
				<div className="col s12" style={{background:'grey', color:'white'}}>
					<div>Company Logo</div>
				</div>
				{(data.company_logo === null|| data.company_logo ==='')&&
				<div className="file-field input-field col s12">
					<div className="btn">
						<span>Company Logo</span>
						<input type="file" id='company_logo'/>
					</div>
					<div className="file-path-wrapper">
						<input className="file-path validate" type="text" />
					</div>
				</div>
				}
				{(data.company_logo && data.company_logo !=='')&&
				<div className="input-field col s6" style={{margin:'50px 0px 50px 0px'}}>
					<div style={{display:'flex'}}>
						<img alt='company_logo' className="materialboxed" width="650" src={data.company_logo} />
						<i className="material-icons" style={{position:'absolute',cursor:'pointer'}} onClick={()=>setData({...data,company_logo:''})}>delete</i>
					</div>
				</div>
				}

				<div className="input-field col s12">
					<textarea value={data.company_introduction||''} onChange={(e)=>setData({...data, company_introduction:e.target.value})} id="company_introduction" className="materialize-textarea" style={{backgroundColor:'#f9f9f9', height:300, border:1, borderColor:'black'}}></textarea>
					<label htmlFor="company_introduction">Detailed Company Introduction</label>
					<div className={(data.company_introduction===null||data.company_introduction?.length<500)?'right red-text':'right green-text'}>{data.company_introduction?.length}/500 chars min</div>
				</div>

				<div className="col s12" style={{background:'grey', color:'white'}}>
					<div>Company Photos</div>
				</div>

				<div className='row'>
					{data.company_photos.map((img,i)=>
					<div key={i} className='col'>
						<div key={i} style={{position:'relative'}}>
							<img alt='img' className='materialboxed' data-caption='caption' src={img} style={{width:200, margin:20, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.5)'}} />
							<a href='#!' onClick={() =>_imgDestroya(img)} className='black-text' style={{position:'absolute', top:5, right:9}}><i className="material-icons">clear</i></a>
						</div>
					</div>
					)}
					<div className='col s12'>
						<div style={{margin:'50px 0px 50px 0px'}}>
							<label style={{height:100,background:'white', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',borderRadius:'20px', cursor:'pointer', display:'block',alignItems:'center', justifyContent:'center', border: '4px dashed rgba(0, 0, 0, 0.35)'}}>
							<div style={{height:'100%',display:'flex', alignItems:'center', justifyContent:'center'}}>
								<h4 style={{margin:0}}>click or drag your photos</h4>
							</div>
							<input id='company_photos' type="file" style={{display:'none'}}  onChange={_fileUpload}/>
							</label>
						</div>
						
					</div>
				</div>
			</div>
			<button className='btn waves-effect waves-light right black'>Save</button>
		</form>
	)
}

export default Step3CompanyCompletedInfo
