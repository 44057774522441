import React, { useEffect, useState, useContext } from 'react';
import {axios} from '../../config'
import { useParams } from 'react-router-dom'
import { UserContext } from '../../UserContext';
import './cotizacion.css'

const Cotizaciones = () => {

	const [data, setData] = useState({files:[]})
	const [cotizacion, setCotizacion] = useState([])
	const { cotizacion_id } = useParams()
	const { user } = useContext(UserContext);

	useEffect(()=>{
		console.log(cotizacion_id)
		axios.post('/api/seller_quote',{cotizacion_id})
		.then(r=>{
			setCotizacion(r.data)

			/**
			  * Ajustar scroll
			  */
			const containers = document.querySelectorAll("#chat");
			containers.forEach(container => {
				container.scrollTop = container.scrollHeight;
			});

		})
		.catch(r=>alert(r))
	},[])

	const _cotizacion_mensaje = (e) => {

		e.preventDefault();
		const conversation_uuid = cotizacion.conversation_uuid
		const mensaje = data.mensaje

		const formData = new FormData();

		formData.append('conversation_uuid', conversation_uuid)
		formData.append('mensaje', mensaje)
		 // Agrega los archivos al FormData
		if(data.files.length>0)
		for (let i = 0; i < data.files.length; i++) {
			formData.append('files[]', data.files[i]);
		}

		axios.post('/api/cotizacion_mensaje', formData)
		.then(r=>{
			setCotizacion({...cotizacion, conversation: {...cotizacion.converzation, messages: [...cotizacion.conversation.messages, r.data] }})
			setData({files:[], mensaje:''})

			/**
			  * Ajustar scroll
			  */
			const containers = document.querySelectorAll("#chat");
			containers.forEach(container => {
				container.scrollTop = container.scrollHeight;
			});

		})
		.catch(r=>alert(r))

	}



	return (
		<div className='card-panel'>
			<h4>Cotización {cotizacion_id}</h4>
			<div>
				<div className='row card-panel'>
					<div className='col'>
						<div>Fecha de solicitud: {new Date(cotizacion.created_at).toLocaleString('es-MX', { timeZone: "America/Mexico_City" })}</div>
						<div>Item no: {cotizacion.object_ref?.producto.codigo}</div>
						<div>Nombre del producto: {cotizacion.object_ref?.producto.nombre}</div>
						<div>cantidad: {cotizacion.cantidad}</div>
					</div>
					{cotizacion.object_ref?.producto.imagenes.length<0&&
					<div className='col'>
						<img style={{objectFit:'cover', height:100}} alt='producto_image' src={cotizacion.object_ref?.producto.imagenes[0]} />
					</div>
					}
					<div className='col'>
						<div style={{fontSize:18}}>Datos de cotización:</div>
						<div>País destino: {cotizacion.object_ref?.cotizacion.country}</div>
						<div>Ciudad destino: {cotizacion.object_ref?.cotizacion.city}</div>
					</div>
				</div>
				<div>
					<div id='chat' data-simplebar style={{border:'solid 1px #D6D6D6', height:600, padding:20, overflow:'auto'}}>
						{cotizacion.conversation?.messages?.map((m, i)=>
						<div key={i} className='row' style={{background:user.name===m.sender_name?'white':'#D3D7CF'}}>
							<div className='col s1'>
								<div><i className="material-icons circle medium">account_circle</i></div>
								{m.sender_name}
							</div>
							<div className='col s10'>
								<label>{new Date(m.created_at).toLocaleString('es-MX', { timeZone: "America/Mexico_City" })}</label>
								<p>{m.content}</p>
								{m.files?.map(f=>
								<div>
									<a href={f} target='_blank' rel='noreferrer'>{f.split('/')[f.split('/').length-1]}</a>
								</div>
								)}
							</div>
						</div>
						)}
						</div>
						<div className="row">
							<form onSubmit={(e)=>_cotizacion_mensaje(e)} className="col s12">
								<div className="row">
									<div className="file-field input-field col s1">
										<div className="btn">
											<i className="material-icons">attach_file</i>
											<input type="file" onChange={(e)=>setData({...data, files:[...data.files, e.target.files[0]] }) } />
										</div>
										<div className="file-path-wrapper" style={{display:'none'}}>
											<input className="file-path validate" type="text" />
										</div>
									</div>
									<div className="input-field col s8">
										<i className="material-icons prefix">mode_edit</i>
										<textarea value={data.mensaje} onChange={(e)=>setData({...data, mensaje: e.target.value})} id="icon_prefix2" className="materialize-textarea" name='mensaje' required />
										<label htmlFor="icon_prefix2">Message</label>
									</div>
									<div className="col s2">
										<button className="btn waves-effect waves-light" type="submit" name="action" style={{marginTop:15}}>Send
											<i className="material-icons right">send</i>
										</button>
									</div>
								</div>
								{data.files?.map((f,i)=>
								<div key={i} style={{color:'blue'}}>file {i+1}: {f.name}</div>
								)}
							</form>
						</div>
				</div>
			</div>
		</div>
	)

}

export default Cotizaciones
