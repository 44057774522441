import React from 'react';

const YoutubeConfig = ({setConfig, config}) => {
	
	const Icons = [
		'https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/redes/y1.png',
		'https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/redes/y2.png',
		'https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/redes/y3.png',
	]	
	

	return(
		<div>
				
				<div className='row' style={{background:'#ECECEC', minHeight:'70px', padding:20, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', marginRight:20}}>
				{Icons.map((c,i)=>
					<div key={i} className='col'>
						<label style={{height:30}}>
							<input onChange={(e)=>setConfig({...config,icon_youtube:c})} name="youtube-group" type="radio" checked={config.icon_youtube===c?true:false}/>
							<span style={{display:'flex', alignItems:'center', margin:5}}>
								<img alt='img' src={c} width='30' height='30'/>
							</span>
						</label>
					</div>
				)}
					<div className="input-field col m12">
						<input value={config.url_youtube} onChange={(e)=>setConfig({...config,url_youtube:e.target.value})} 
								id="url_youtube"  name='url' type="url" className="validate" />
						
						<label htmlFor="url_youtube">URL</label>
					</div>
				</div>
		</div>
				
	)
}

export default YoutubeConfig
