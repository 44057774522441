import React, { useEffect, useState } from 'react';
import { axios } from '../../../config'
import M from 'materialize-css'

const Step1CompanyBasicInfo = ({percent_calc}) => {

	const [data, setData] = useState({company_business_type:[], company_business_license_picture:'', company_tax_registration_certificate_picture:''})
	const company_business_type = ['Manufacturer', 'Trading Company', 'Buying Office', 'Agent', 'Distributor/Wholesaler', 'Government ministry/Bureau/Commission', 'Association', 'Business Service (Transportation, Finance, Travel, Ads, etc.)'].sort()

	useEffect(()=>{
		axios.get('/api/seller_company_basic')
		.then(r=>{
			setData(r.data)
			percent_calc(r.data.states)
		})
	},[])

	const _sendCompanyBasicInfo = (e) => {
		e.preventDefault()
		console.log(data)

		/*
		   * get images
		   */
		let company_tax_registration_certificate_picture = document.getElementById('company_tax_registration_certificate_picture')?.files[0]
		let company_business_license_picture = document.getElementById('company_business_license_picture')?.files[0]
		let company_operational_address_proof_picture = document.getElementById('company_operational_address_proof_picture')?.files[0]

		const formData = new FormData();
		formData.append('data', JSON.stringify(data))
		formData.append('company_tax_registration_certificate_picture', company_tax_registration_certificate_picture)
		formData.append('company_business_license_picture', company_business_license_picture)
		formData.append('company_operational_address_proof_picture', company_operational_address_proof_picture)



		console.log('data', JSON.stringify(data))
		console.log(company_tax_registration_certificate_picture)
		axios.post('/api/seller_company_basic', formData)
		.then(r=>{
			setData(r.data)
			console.log(r.data)
			percent_calc(r.data.states)
			M.toast({html:'Updated!'})

			/**
			  * Next tab
			  */
			const tabs = document.querySelector(".tabs")
			const instance = M.Tabs.getInstance(tabs)
			instance.select('step2')
			window.scrollTo(0, 0)

		})
		.catch(e=>alert(e))
	}

	const _update_company_business_type = (e, value) =>{
		const aux = [...data.company_business_type]
		if(e.target.checked){
			setData({...data, company_business_type:aux.includes(value)?aux:[...aux, value].sort()})
		}else{
			aux.splice(aux.indexOf(value), 1)
			const newData = {...data, company_business_type:aux}
			setData(newData)
		}
	}
	return(
		<form onSubmit={_sendCompanyBasicInfo}>
			<div className="row">
				{company_business_type.map((business, i)=>
				<div key={i} className="input-field col">
					<p>
						<label>
							<input type="checkbox" checked={data.company_business_type.includes(business)} onChange={(e)=>_update_company_business_type(e, business)}/>
							<span>{business}</span>
						</label>
					</p>
				</div>
				)}
			</div>

			<div className="row">
				<div className="input-field col s12">
					<input value={data.company_name||''} onChange={(e)=>setData({...data, company_name:e.target.value})} id="company_name" type="text" className="validate" />
					<label htmlFor="company_name">Company name</label>
				</div>
			</div>

			<div className="row">
				<div className="input-field col s6">
					<input value={data.company_business_license||''} onChange={(e)=>setData({...data, company_business_license:e.target.value})} id="company_business_license" type="text" className="validate" placeholder='Number'/>
					<label htmlFor="company_business_license">Business License</label>
				</div>
				{(data.company_business_license_picture === null|| data.company_business_license_picture ==='')&&
				<div className="file-field input-field col s6">
					<div className="btn">
						<span>License File</span>
						<input type="file" id='company_business_license_picture'/>
					</div>
					<div className="file-path-wrapper">
						<input className="file-path validate" type="text" />
					</div>
				</div>
				}
				{(data.company_business_license_picture && data.company_business_license_picture !=='')&&
				<div className="input-field col s6">
					<a href={data.company_business_license_picture} style={{marginTop:20, position:'absolute'}} target='_blank' rel="noreferrer">{data.company_business_license_picture}</a>
					<i className="material-icons" style={{position:'absolute', right:0, cursor:'pointer'}} onClick={()=>setData({...data,company_business_license_picture:''})}>delete</i>
				</div>
				}
			</div>

			<div className="row">
				<div className="input-field col s6">
					<input value={data.company_tax_registration_certificate||''} onChange={(e)=>setData({...data, company_tax_registration_certificate:e.target.value})} id="company_tax_registration_certificate" type="text" className="validate" placeholder='Number'/>
					<label htmlFor="company_tax_registration_certificate">Tax registration certificate</label>
				</div>
				{(data.company_tax_registration_certificate_picture === null|| data.company_tax_registration_certificate_picture ==='')&&
				<div className="file-field input-field col s6">
					<div className="btn">
						<span>Tax certificate</span>
						<input type="file" id='company_tax_registration_certificate_picture'/>
					</div>
					<div className="file-path-wrapper">
						<input className="file-path validate" type="text" />
					</div>
				</div>
				}
				{(data.company_tax_registration_certificate_picture && data.company_tax_registration_certificate_picture !=='')&&
				<div className="input-field col s6">
					<a href={data.company_tax_registration_certificate_picture} style={{marginTop:20, position:'absolute'}} target='_blank' rel="noreferrer">{data.company_tax_registration_certificate_picture}</a>
					<i className="material-icons" style={{position:'absolute', right:0, cursor:'pointer'}} onClick={()=>setData({...data,company_tax_registration_certificate_picture:''})}>delete</i>
				</div>
				}
			</div>
			<div className='row'>
				<div className="col s12" style={{background:'grey', color:'white'}}>
					<div>Company Operational Address</div>
				</div>
				<div className="input-field col s6">
					<input value={data.company_operational_address_country||''} onChange={(e)=>setData({...data, company_operational_address_country:e.target.value})} id="company_operational_address_country" type="text" className="validate" />
					<label htmlFor="company_operational_address_country">Country</label>
				</div>
				<div className="input-field col s6">
					<input value={data.company_operational_address_city||''} onChange={(e)=>setData({...data, company_operational_address_city:e.target.value})} id="company_operational_address_city" type="text" className="validate" />
					<label htmlFor="company_operational_address_city">City</label>
				</div>
				<div className="input-field col s6">
					<input value={data.company_operational_address_streetname_number||''} onChange={(e)=>setData({...data, company_operational_address_streetname_number:e.target.value})} id="company_operational_address_streetname_number" type="text" className="validate" />
					<label htmlFor="company_operational_address_streetname_number">Street Name & Number</label>
				</div>
				<div className="input-field col s6">
					<input value={data.company_operational_address_zipcode||''} onChange={(e)=>setData({...data, company_operational_address_zipcode:e.target.value})} id="company_operational_address_zipcode" type="text" className="validate" />
					<label htmlFor="company_operational_address_zipcode">Zip/Postal Code</label>
				</div>
				{(data.company_operational_address_proof_picture === null|| data.company_operational_address_proof_picture ==='')&&
				<div className="input-field col s6">
					<div className="file-field input-field">
						<div className="btn">
							<span>Proof of Address</span>
							<input type="file" id='company_operational_address_proof_picture'/>
						</div>
						<div className="file-path-wrapper">
							<input className="file-path validate" type="text" />
						</div>
					</div>
				</div>
				}
				{(data.company_operational_address_proof_picture && data.company_operational_address_proof_picture !=='')&&
				<div className="input-field col s6" style={{margin:'50px 0px 50px 0px'}}>
					<a href={data.company_operational_address_proof_picture} style={{marginTop:20, position:'absolute'}} target='_blank' rel="noreferrer">{data.company_operational_address_proof_picture}</a>
					<i className="material-icons" style={{position:'absolute', right:0, cursor:'pointer'}} onClick={()=>setData({...data,company_operational_address_proof_picture:''})}>delete</i>
				</div>
				}
			</div>

			<div className='row'>
				<div className="col s12" style={{background:'grey', color:'white'}}>
					<div>Factory Address</div>
				</div>
				<div className="input-field col s6">
					<input value={data.company_factory_address_country||''} onChange={(e)=>setData({...data, company_factory_address_country:e.target.value})} id="company_factory_address_country" type="text" className="validate" />
					<label htmlFor="company_factory_address_country">Country</label>
				</div>
				<div className="input-field col s6">
					<input value={data.company_factory_address_city||''} onChange={(e)=>setData({...data, company_factory_address_city:e.target.value})} id="company_factory_address_city" type="text" className="validate" />
					<label htmlFor="company_factory_address_city">City</label>
				</div>
				<div className="input-field col s6">
					<input value={data.company_factory_address_streetname_number||''} onChange={(e)=>setData({...data, company_factory_address_streetname_number:e.target.value})} id="company_factory_address_streetname_number" type="text" className="validate" />
					<label htmlFor="company_factory_address_streetname_number">Street Name & Number</label>
				</div>
				<div className="input-field col s6">
					<input value={data.company_factory_address_zipcode||''} onChange={(e)=>setData({...data, company_factory_address_zipcode:e.target.value})} id="company_factory_address_zipcode" type="text" className="validate" />
					<label htmlFor="company_factory_address_zipcode">Zip/Postal Code</label>
				</div>
				<div className="input-field col s6">
					<input value={data.annual_output_main_products||''} onChange={(e)=>setData({...data, annual_output_main_products:e.target.value})} id="annual_output_main_products" type="number" className="validate" />
					<label htmlFor="annual_output_main_products">Annual Output of Main Products ($USD)</label>
				</div>
			</div>
			<button className='btn waves-effect waves-light right black'>Save</button>
		</form>
	)
	
}

export default Step1CompanyBasicInfo
