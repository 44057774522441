import React, { useState, useEffect } from 'react'
import { axios } from '../../config'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const Movimientos = () => {

	const [searchG, setSearchG] = useState('')
	const [searchPE, setSearchPE] = useState('')
	const [searchCE, setSearchCE] = useState('')
	const [data, setData] = useState({ granel: [], calidadPorEvaluar: [], calidadDisponible: [] })
	const id2List = { droppableGranel: 'granel', droppablePorEvaluar: 'calidadPorEvaluar', droppableDisponible: 'calidadDisponible' }

	useEffect(()=>{
		axios.get('/api/inventarios')
		.then(r=>{
			setData({granel:r.data.granel, calidadPorEvaluar:r.data.calidadPorEvaluar, calidadDisponible: r.data.calidadDisponible})
		})
		.catch(r=>alert(r))
	},[])

	const move = (source, destination, droppableSource, droppableDestination) => {
		const sourceClone = Array.from(source);
		const destClone = Array.from(destination);
		const [removed] = sourceClone.splice(droppableSource.index, 1);
		destClone.splice(droppableDestination.index, 0, removed);
		const result = {};
		result[droppableSource.droppableId] = sourceClone;
		result[droppableDestination.droppableId] = destClone;
		return result;
	}

	const onDragEnd = result => {
		const { source, destination, draggableId } = result;
		const caja_contenedor = draggableId.slice(3)
		let caja_id = caja_contenedor.split('_')[0]
		let contenedor_id = caja_contenedor.split('_')[1]

		if (!destination)return

		/*
		   *
		   * Evaluar caja de granel o calidadPorEvaluar
		   */
		if(destination.droppableId==='droppableDisponible'){
			const granel = source.droppableId==='droppableGranel'?data.granel.filter(c=>c[0].caja_contenedor!==caja_contenedor):data.granel
			const calidadPorEvaluar = source.droppableId==='droppablePorEvaluar'?data.calidadPorEvaluar.filter(g=>g[0].caja_contenedor!==caja_contenedor):data.calidadPorEvaluar
			axios.post('/api/evaluarCaja', {caja_id, contenedor_id})
			.then(r=>{
				const calidadDisponible=[...r.data.map(d=>({codigo:d.codigo, piezas:d.cantidad, style:1})), ...data.calidadDisponible]
				setData({granel, calidadPorEvaluar, calidadDisponible })
			})
			.catch(r=>alert(r))
			return
		}
		if (source.droppableId !== destination.droppableId) {
			const result = move(data[id2List[source.droppableId]], data[id2List[destination.droppableId]], source, destination)
			setData({ ...data, granel:result.droppableGranel, calidadPorEvaluar: result.droppablePorEvaluar });

			if(destination.droppableId==='droppablePorEvaluar'){
				axios.post('/api/granelACalidadEvaluar', {caja_id, contenedor_id})
				.then(r=>console.log(r.data))
				.catch(r=>alert(r))
			}
			if(destination.droppableId==='droppableGranel'){
				axios.post('/api/calidadEvaluarAGranel', {caja_id, contenedor_id})
				.then(r=>console.log(r.data))
				.catch(r=>alert(r))
			}
		}
	};

	return (
		<div className='card-panel'>
			<h4>Movimientos</h4>
			<div className='row'>
			<DragDropContext onDragEnd={onDragEnd}>
				{/**
				  *
				  * GRANEL
				  *
				  */}
				<div className='col s4'>
					<div className='row'>
						<div className='col s6'>
						<h5>GRANEL</h5>
						</div>
						<div className='col s6'>
							<nav className='white'>
								<div className="nav-wrapper black-text">
									<form>
										<div className="input-field">
											<input value={searchG} onChange={e=>setSearchG(e.target.value)} id="search" type="search" required placeholder='Caja, Código'/>
											<label className="label-icon" htmlFor="search"><i className="material-icons black-text">search</i></label>
											<i className="material-icons">close</i>
										</div>
									</form>
								</div>
							</nav>
						</div>
					</div>

					<Droppable droppableId="droppableGranel">
					{(provided, snapshot) => (
						<div ref={provided.innerRef} style={{height: '40vw', overflow:'auto'}} >
						{data.granel.filter(g=>g[0].caja_id.includes(searchG)||g.some(g=>g.codigo.includes(searchG))).map((item, index) => (
							<Draggable key={'id-'+item[0].caja_contenedor} draggableId={'id-'+item[0].caja_contenedor} index={index}>
							{(provided, snapshot) => (
								<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
									<div style={{padding: 20}}>
										<div className="carton">
											<div className="front">
												<div className='row'>
													<div className="barcode right">
														<div className="barcode__scan"></div>
														<div className="barcode__id">{item[0].caja_contenedor}</div>
													</div>
												<div className='bold' style={{position:'relative',top:40, left:10}}>Códigos:</div>
												</div>
												<div style={{height:150, overflow:'auto'}}>
												{item.map((p,i)=><div key={i}>{p.codigo} {p.nombre_del_producto.slice(0,30)}... {p.cantidad}pz</div>)}
												</div>
											</div>
											<div className="side cente" style={{padding:5}}>
												<div className='bold store-name'></div>
												<div className="barcode" style={{position:'fixed', bottom:2}}>
													<div className="barcode__scan" style={{width: 115}}></div>
													<div className="barcode__id" style={{font: "700 14px/1 'Jura'"}}>C000086181</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							</Draggable>
						)).slice(0,10)}
						{provided.placeholder}
						</div>
					)}
					</Droppable>
				</div>
				{/**
				  *
				  * CALIDAD POR EVALUAR
				  *
				  */}
				<div className='col s4'>
					<div className='row'>
						<div className='col s6'>
						<h5>Calidad Por Evaluar</h5>
						</div>
						<div className='col s6'>
							<nav className='white'>
								<div className="nav-wrapper black-text">
									<form>
										<div className="input-field">
											<input value={searchPE} onChange={e=>setSearchPE(e.target.value)} id="search" type="search" required placeholder='Caja_id, Código'/>
											<label className="label-icon" htmlFor="search"><i className="material-icons black-text">search</i></label>
											<i className="material-icons">close</i>
										</div>
									</form>
								</div>
							</nav>
						</div>
					</div>
					<Droppable droppableId="droppablePorEvaluar">
						{(provided, snapshot) => (
						<div ref={provided.innerRef} style={{height: '40vw', overflow:'auto'}} >
							{data.calidadPorEvaluar.filter(g=>g[0].caja_id.includes(searchPE)||g.some(g=>g.codigo.includes(searchPE))).map((item, index) => (
							<Draggable key={'id-'+item[0].caja_contenedor} draggableId={'id-'+item[0].caja_contenedor} index={index}>
								{(provided, snapshot) => (
								<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
									<div style={{padding: 30}}>
										<div className="carton">
											<div className="front">
												<div className='row'>
													<div className="barcode right">
														<div className="barcode__scan"></div>
														<div className="barcode__id">{item[0].caja_contenedor}</div>
													</div>
												<div className='bold' style={{position:'relative',top:40, left:10}}>Códigos:</div>
												</div>
												<div style={{height:150, overflow:'auto'}}>
												{item.map((p,i)=><div key={i}>{p.codigo} {p.nombre_del_producto.slice(0,30)}... {p.cantidad}pz</div>)}
												</div>
											</div>
											<div className="side cente" style={{padding:5}}>
												<div className='bold store-name'></div>
												<div className="barcode" style={{position:'fixed', bottom:2}}>
													<div className="barcode__scan" style={{width: 115}}></div>
													<div className="barcode__id" style={{font: "700 14px/1 'Jura'"}}>C000086181</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								)}
							</Draggable>
							)).slice(0,10)}
						{provided.placeholder}
						</div>
						)}
					</Droppable>
				</div>
				<div className='col s4'>
					<div className='row'>
						<div className='col s6'>
						<h5>Calidad Evaluado</h5>
						</div>
						<div className='col s6'>
							<nav className='white'>
								<div className="nav-wrapper black-text">
									<form>
										<div className="input-field">
											<input value={searchCE} onChange={e=>setSearchCE(e.target.value)} id="search" type="search" required placeholder='Caja_id'/>
											<label className="label-icon" htmlFor="search"><i className="material-icons black-text">search</i></label>
											<i className="material-icons">close</i>
										</div>
									</form>
								</div>
							</nav>
						</div>
					</div>
					<Droppable droppableId="droppableDisponible">
						{(provided, snapshot) => (
						<div ref={provided.innerRef} style={{height: '40vw', overflow:'auto'}} >
							<table className="highlight">
								<thead>
									<tr>
										<th>#</th>
										<th>Código</th>
										<th>Piezas</th>
									</tr>
								</thead>
								<tbody>
								{data.calidadDisponible.filter(c=>c.codigo.includes(searchCE)).map((d,i)=>
									<tr key={i} style={d.style?{backgroundColor:'#D5FFAD'}:{}}>
										<td>{i+1}</td>
										<td>{d.codigo}</td>
										<td>{d.piezas}</td>
									</tr>
								)}
								</tbody>
							</table>
							{provided.placeholder}
						</div>
						)}
					</Droppable>
				</div>
				</DragDropContext>
			</div>
		</div>
	)
}

export default Movimientos
