import React from 'react';

const FacebookConfig = ({setConfig, config}) => {

	const faceIcons = [
		'https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/redes/f1.png',
		'https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/redes/f2.png',
		'https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/redes/f3.png',
	]	
	

	return(
		<div>
				
				<div className='row' style={{background:'#ECECEC', minHeight:'70px', padding:20, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', marginRight:20}}>
				{faceIcons.map((c,i)=>
					<div key={i} className='col'>
						<label style={{height:30}}>
							<input 
							onChange={(e)=>setConfig({...config,icon_facebook:c})} name="facebook-group" type="radio" checked={config.icon_facebook===c?true:false}/>
							<span style={{display:'flex', alignItems:'center', margin:5}}>
								<img alt='img' src={c} width='30' height='30'/>
							</span>
						</label>
					</div>
				)}
					<div className="input-field col m12">
						<input value={config.url_facebook} onChange={(e)=>setConfig({...config, url_facebook:e.target.value})} id="url_facebook" name='text' type="url" className="validate" />
						<label htmlFor="url_facebook">URL</label>
					</div>
				</div>
		</div>
				
	)
}

export default FacebookConfig
