import React, { useState, useEffect } from 'react'
import { axios } from '../../config'
import M from 'materialize-css'

const NewOrder = () => {

	const [data, setData]=useState({excel:'', descripcion:'', sucursal_id:''})
	const [sucursales, setSucursales]=useState([])

	useEffect(()=>{
		axios.get('/api/sucursales')
		.then(r=>{
			setSucursales(r.data)
			var elems = document.querySelectorAll('select');
			M.FormSelect.init(elems, {});
		})
		.catch(r=>alert(r))
	},[])

	const _order = e => {
		var reader = new FileReader();
		reader.readAsDataURL(e.target.files[0]);
		reader.onload = () => {
			setData({...data, excel:reader.result})
		};
	} 

	const _push = () => {
		if(data.excel!=='' && data.descripcion!=='' && data.sucursal_id!=='')
			axios.post('/api/newOrder', data)
			.then(r=>{
				M.toast({html: 'ha solicitado un nuevo pedido'})
				setData({excel:'', descripcion:'', sucursal_id:''})
				var elems = document.querySelectorAll('select');
				document.querySelector('input#name').value='';
				document.querySelector('input#input-file').value='';
				M.FormSelect.init(elems, {});
			})
			.catch(r=>alert(r))
		else alert('ingrese todos los campos')
	}

	return (
		<div className="container">
			<div className="row">
				<div className="card-panel">
					<h4>Pedido Nuevo</h4>
					<a href={axios.defaults.baseURL+'/plantillas/plantillaPedido.xlsx'} className='btn waves-effect waves-light right'>Descargar Plantilla</a>
					<div className="row">
						<div className="input-field col s12">
							<select value={data.sucursal_id} onChange={(e)=>setData({...data, sucursal_id:e.target.value})}>
								<option value="" disabled>seleccionar</option>
								{sucursales.map((s,i)=>
									<option key={i} value={s.id}>{s.nombre}</option>
								)}
							</select>
							<label>Seleccionar Sucursal</label>
						</div>
					</div>
					<div className="row">
						<form className="col s12">
							<div className="row">
								<div className="input-field col s12">
									<input id="descripcion" type="text" value={data.descripcion} onChange={(e)=>setData({...data, descripcion:e.target.value})}/>
									<label htmlFor="descripcion">Descripción</label>
								</div>
							</div>
						</form>
					</div>
					<div className="row">
						<div className="input-field">
							<div className="file-field input-field">
								<div className="btn">
									<span>Pedido</span>
									<input id='input-file' onChange={_order} type="file" />
								</div>
								<div className="file-path-wrapper">
									<input id="name" className="file-path validate" type="text" required/>
								</div>
							</div>
						</div>
						<button onClick={_push} className="btn waves-effect waves-light right" type="submit" name="action">Subir
							<i className="material-icons right">send</i>
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
export default NewOrder
