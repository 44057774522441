import React, { useEffect, useState } from 'react';
import M from 'materialize-css'
import { axios } from '../../config'

const ContactoWeb = () => {

	const [contactosWeb, setContactosWeb] = useState([])

	useEffect(()=>{
		axios.get('/api/contactoweb')
		.then(r=>{
			setContactosWeb(r.data)
		})
		.catch(r=>alert(r))
	},[])

	return (
		<>
		<div className='card-panel'>
			<h4>ContactoWeb</h4>
			<table>
				<thead>
					<tr>
						<th>#</th>
						<th>Nombre</th>
						<th>Correo</th>
						<th>Mensaje</th>
					</tr>
				</thead>
				<tbody>
				{contactosWeb.map((b,i)=>
					<tr key={i}>
						<td>{i+1}</td>
						<td>{b.nombre}</td>
						<td>{b.correo}</td>
						<td>
							<textarea disabled style={{height:100}} value={b.mensaje} />
						</td>
					</tr>
				)}
				</tbody>
			</table>

		</div>
		</>
	)
}

export default ContactoWeb
