import React from 'react';
import './App.scss';
import 'materialize-css';
import 'materialize-css/sass/materialize.scss';
import Dashboard from './components/dashboard'
import Login from './components/auth/login'
import Create from './components/auth/signup'
import { BrowserRouter as Router, Route, Switch /*Link*/ } from "react-router-dom";
import {UserProvider} from './UserContext';

const App=()=>{

	return (
		<UserProvider>
		<Router>
			<Switch>
				<Route exact path="/login" component={Login} />
				<Route exact path="/create" component={Create} />
				<Route path="/" component={Dashboard} />
			</Switch>
		</Router>
		</UserProvider>
	);
}

export default App;
