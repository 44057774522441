import React from 'react';

const Progressnav = (props) => {

	const {estatus} = props

	let stepNumber = (['step_one','step_two','step_three','step_four','step_five','step_six'].indexOf(estatus)+1)
	
	const _renderLottie = (position) =>{
	
		switch(position){
			case 1:
				if(estatus === 'step_one')return <img alt='edit' style={{width:'60px'}} src='../edit.gif'/> 
				else  return <img alt='check' src='../check.gif'/>;
			case 2:
				if(estatus === 'step_two')return <img alt='edit' style={{width:'60px'}} src='../edit.gif'/>
				else{
					if(position > stepNumber) return <div className='centrar'> 2 </div> 
					else return <img alt='check' src='../check.gif'/>;
				} 
			case 3:
				if(estatus === 'step_three')return <img alt='edit' style={{width:'60px'}} src='../edit.gif'/>
				else{
					if(position > stepNumber) return <div className='centrar'> 3 </div> 
					else return <img alt='check' src='../check.gif'/>;
				} 
			case 4:
				if(estatus === 'step_four')return <img alt='edit' style={{width:'60px'}} src='../edit.gif'/>
				else{
					if(position > stepNumber) return <div className='centrar'> 4 </div> 
					else return <img alt='check' src='../check.gif'/>;
				} 
			case 5:
				if(estatus === 'step_five')return <img alt='edit' style={{width:'60px'}} src='../edit.gif'/>
				else{
					if(position > stepNumber) return <div className='centrar'>3</div> //se cambio 5 por 3 para eliminar los pasos 3 y 7 que ya no son necesarios, usando la misma structure
					else return <img alt='check' src='../check.gif'/>;
				}
			case 6:
				if(estatus === 'step_six')return <img alt='edit' style={{width:'60px'}} src='../edit.gif'/>
				else{
					if(position > stepNumber) return <div className='centrar'> 6 </div> 
					else return <img alt='check' src='../check.gif'/>;
				}
			default:
				break
		}
	}

	return (
		<div className='container-a'>
			<div className='row cero' style={{marginBottom:'0!important',}}>
				<div className='col s12 m3 cero centrar'>
					<div className='centrar circulo active-bg'>
					   { _renderLottie(1)}
					</div>
				</div>
				<div className='col s12 m9 pasos centrar'> <p className='active-font'>Configuración de la tienda</p> </div>
			</div>
			<div className='row cero' style={{marginBottom:'0!important',}}>
				<div className='col s12 m3 cero centrar'>
					<div className='centrar linea'> <div className='centrar'></div> </div>
				</div>
				<div className='col s12 m9 pasos centrar'> </div>
			</div>

			<div className='row cero' style={{marginBottom:'0!important',}}>
				<div className='col s12 m3 cero centrar'>
					<div className='centrar circulo'> 
						{_renderLottie(2)}
					</div>
				</div>
				<div className='col s12 m9 pasos centrar'> <p className=''> Imagenes de Carrusel</p> </div>
			</div>
			<div className='row cero' style={{marginBottom:'0!important',}}>
				<div className='col s12 m3 cero centrar'>
					<div className='centrar linea'> <div className='centrar'></div> </div>
				</div>
				<div className='col s12 m9 pasos centrar'> </div>
			</div>

			{/*
			<div className='row cero' style={{marginBottom:'0!important',}}>
				<div className='col s12 m3 cero centrar'>
					<div className='centrar circulo'> 
						{_renderLottie(3)}
					</div>
				</div>
				<div className='col s12 m9 pasos centrar'> <p className=''>Crear primer departamento</p> </div>
			</div>
			<div className='row cero' style={{marginBottom:'0!important',}}>
				<div className='col s12 m3 cero centrar'>
					<div className='centrar linea'> <div className='centrar'></div> </div>
				</div>
				<div className='col s12 m9 pasos centrar'> </div>
			</div>
			<div className='row cero' style={{marginBottom:'0!important',}}>
				<div className='col s12 m3 cero centrar'>
					<div className='centrar circulo'> 
						{_renderLottie(4)}
					</div>
				</div>
				<div className='col s12 m9 pasos centrar'> <p className=''>Alta de Producto</p> </div>
			</div>
			<div className='row cero' style={{marginBottom:'0!important',}}>
				<div className='col s12 m3 cero centrar'>
					<div className='centrar linea'> <div className='centrar'></div> 
					</div>
				</div>
				<div className='col s12 m9 pasos centrar'> </div>
			</div>
			*/}
			<div className='row cero' style={{marginBottom:'0!important',}}>
				<div className='col s12 m3 cero centrar'>
					<div className='centrar circulo'> {_renderLottie(5)}</div>
				</div>
				<div className='col s12 m9 pasos centrar'> <p className=''>Agregar redes sociales y su config</p> </div>
			</div>
			
		{/*
			<div className='row cero' style={{marginBottom:'0!important',}}>
				<div className='col s12 m3 cero centrar'>
					<div className='centrar linea'> <div className='centrar'></div> 
					</div>
				</div>
				<div className='col s12 m9 pasos centrar'> </div>
			</div>
			<div className='row cero' style={{marginBottom:'0!important',}}>
				<div className='col s12 m3 cero centrar'>
					<div className='centrar circulo'> {_renderLottie(6)}</div>
				</div>
				<div className='col s12 m9 pasos centrar'> <p className=''>Configuración de correo</p> </div>
			</div>
		*/}
			
		</div>
	);
};

export default Progressnav;
