import React, { useState, useEffect } from 'react'
import { axios } from '../../config'

const NotFoundBoxes = () => {

	const [notBoxes, setNotBoxes]=useState([])

	useEffect(()=>{
		axios.get('/api/notFoundBoxes')
		.then(r=>{
			setNotBoxes(r.data)
		})
		.catch(r=>alert(r))
	},[])

	return (
			<div className="row">
				<div className="card-panel">
				<h4>Cajas no encontradas para el envio a calidad</h4>
				<table className="highlight">
					<thead>
						<tr>
							<th>#</th>
							<th>Contenedor</th>
							<th>caja_id</th>
							<th>Numero Caja</th>
							<th>Código</th>
							<th>Nombre del producto</th>
							<th>Cantidad</th>
							<th>Display</th>
							<th>Recibida en el contenedor</th>
						</tr>
					</thead>
					<tbody>
					{notBoxes.map((m,i)=>
						<tr key={i}>
							<td>{i+1}</td>
							<td>{m.contenedor}</td>
							<td>{m.caja_id}</td>
							<td>{m.numero_caja}</td>
							<td>{m.codigo}</td>
							<td>{m.nombre_del_producto}</td>
							<td>{m.cantidad}</td>
							<td>{m.display}</td>
							<td>{m.recibio_recepcion?'SI':'NO'}</td>
						</tr>
					)}
					</tbody>
				</table>
				</div>
			</div>
	)
}
export default NotFoundBoxes
