import React, { useEffect, useState } from 'react';
import { axios } from '../../config';
import { useParams } from 'react-router-dom';
import M from 'materialize-css';

const Comentarios = () => {
    const {codigo} = useParams();
    const [reviews,setReviews] = useState([]);
    const [calificacionGlobal,setCalificacionGlobal] = useState(0);
    const [filtros,setFiltros] = useState({visibles:null,visibleCheck:false,calificacion:"0",calSelec:""});
    const [domain, setDomain] = useState('');

    useEffect(()=>{
        
        axios.get(`/api/product_reviews/${codigo}`).then((r)=>{
            console.log("response",r.data)
            setReviews(r.data) 
            if(r.data.length>0){
                setCalificacionGlobal(Math.ceil(r.data.reduce((a,b)=>(a+b.calificacion),0)/r.data.length))
		setDomain(r.data[0].domain)
            }
            M.FormSelect.init(document.querySelectorAll('select'), {});
        }).catch(e=>console.log('error',e))
    },[])

    const _renderStars = (calificacion = 0) =>{
        let icons =[]
        for(let i = 0;i<5;i++) icons.push(<i key={i} className="material-icons" style={{color:"#FA9C1C"}}>{(i+1<=calificacion)?'star':'star_border'}</i>)
        return icons;
    }

    const _resetFiltros = ()=>{
        setFiltros({visibles:null,visibleCheck:false,calificacion:0,calSelec:""})
        M.FormSelect.init(document.querySelectorAll('select'), {});
    }

    const _borrar = (id) =>{
        setReviews(reviews.filter(r=>r.id!==id))
    }
    const _ocultar = (obj,checked) =>{
        const newReviews = reviews.map(r=>r.id===obj.id?{...obj,visible_marketplace:checked}:r)
		setReviews(newReviews)
		axios.post('/api/updatestatus',{id:obj.id,visible_marketplace:checked})
		.then((r) => {
			M.toast({html:'Actualizado!'})
		})
		.catch(r=>alert(r))	
    }
  
    return (
        <div>
            <div className="col s12" style={{marginTop:"50px"}}>
                <div style={{fontSize:32, fontWeight:'bold'}}>Evaluaciones de: <a href={`https://${domain}/product?codigo=${codigo}`} target='_blank' rel="noreferrer">{codigo}</a></div>
                <h6>Total: <span>{reviews.length} </span>Calificación Global:{calificacionGlobal}</h6> 
                <div className='card' style={{padding:15}}>
                    <h5>Filtros</h5>
                    <div className='row'>
                        <div className='col s3 '>
                            <select value={filtros.calSelec} onChange={(e)=>setFiltros({...filtros,calificacion:e.target.value})}>
                                <option value="0">Seleccionar calificacion</option>
                                <option value="1">1 estrella</option>
                                <option value="2">2 estrellas</option>
                                <option value="3">3 estrellas</option>
                                <option value="4">4 estrellas</option>
                                <option value="5">5 estrellas</option>
                            </select>
                        </div>
                        <div className='col s2 centrar' style={{height:50}}>
                            <label>
                                <input type="checkbox" onChange={(e)=>setFiltros({...filtros,visibles:e.target.checked?1:0,visibleCheck:!filtros.visibleCheck})} checked={filtros.visibleCheck}/>
                                <span>Mostrar visibles</span>
                            </label>
                        </div>
                       
                        <button onClick={_resetFiltros} className="btn waves-effect waves-light" name="action" >Borrar Filtros</button>
                       
                    </div>
                    
                </div>
                {reviews.filter(r=>(filtros.visibles!==null?r.visible_marketplace === filtros.visibles:r) && (filtros.calificacion>0?r.calificacion == filtros.calificacion:r)).map(r=>
                    <div key={r.id} className="card s12" style={{marginTop:20,position:"relative",padding:20}}>
                        <div className='centrar-l' style={{position:'absolute', right:15,top:15}}>
                            {r.reportes_count>0&&
                            <>
                                <span className='bold'>{r.reportes_count}</span><i className="material-icons red-text">error</i>&nbsp;&nbsp;
                            </>
                            }
                            <div className="switch" style={{marginRight:30}}>
                                <label>
                                    Oculto
                                    <input className='vistos' type="checkbox" checked={r.visible_marketplace} onChange={(e)=>_ocultar(r,e.target.checked)}/>
                                    <span className="lever"></span>
                                    Visible
                                </label>
                            </div> 
                           <span className='bold'>{r.likes_comentario_count}</span>  <i className="material-icons">favorite_border</i>
                        </div>

                        <div className='row'>
                            <div className='col s12 centrar-l'>
                                <i className="material-icons">account_circle</i><span> {r.user.name} </span>
                            </div>
                            <div className="col s12 centrar-l">
                               {_renderStars(r.calificacion)}
                                <span style={{fontSize:16}} className='bold'>{r.titulo}</span>
                            </div>
                            <div style={{fontSize:14}} className='col s12'>
                                Fecha de publicación: {new Date(r.updated_at).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })} a las {new Date(r.updated_at).toLocaleTimeString('es-MX',{ hour12:true})}
                            </div>
                            <div className='col s12 bold' style={{marginTop:10}}>
                                {r.comentario}
                            </div>
                            {r.reportes_count>0&&
                            <div className='red-text' style={{padding:10}}>
                                Nota: Este comentario tiene pendiente uno o mas reportes.
                            </div>
                            }
                        </div>
                        <div style={{textAlign:'right',marginTop:30}}>
                            
                            <button className="waves-effect waves-light btn red" onClick={()=>_borrar(r.id)}>Eliminar Comentario</button>
                        </div>
                    </div>
                )}  
            </div>
        </div>
       
    );
};

export default Comentarios;
