import React ,{useState,useEffect} from 'react';
import { axios } from '../../../config';
import M from 'materialize-css';
import { useHistory } from 'react-router-dom';

const StepThree = () => {

	const [data, setData] = useState({departamento:'', descripcion:''})
	const [foto, setFoto] = useState(false)
	const history = useHistory();

	useEffect(() => {
		//buscamos si existe un departamento en caso de que el usuario quiera editar el primero
		axios.get('/api/first').then((r)=>{
			if(r.data) setData(r.data)
		}).catch(e=>console.log(e))
	}, [])

	const _return = (e) => {
		e.preventDefault();
		axios.put('/api/savestatus',{config_store:{"wizard_config":{"installed":0,"status":"step_two"}}}).then((res)=>{
			history.push("/")
			window.location.reload()
		}).catch(e=>console.log("error",e))
	}

	const _pushData = (e) => {
		e.preventDefault()

		const dataDep = new FormData()
		dataDep.append('data', JSON.stringify(data))
		dataDep.append('files', foto);
		
		if(!foto&&!data.img) return alert("Por favor sube una imagen de departamento para continuar")

		axios.post('/api/departamento',dataDep)
		.then((r) => {
			M.toast({html:'Departamento registrado'})
			axios.put('/api/savestatus',{config_store:{"wizard_config":{"installed":0,"status":"step_four"}}}).then((res)=>{
				history.push("/")
				window.location.reload()
			}).catch(e=>console.log("error",e))
		})
		.catch(r=>{
			if(r.response.status===400)
			alert(r.response.data.message)
			else alert(r)
		})
	}

	return (
		<div className='padding-20' style={{position:'relative',minHeight:600}}>
			<h5>Alta de Departamento</h5>
			<h6>Todos sus cambios o ajustes se verán reflejados al presionar el botón de SIGUIENTE en la parte inferior</h6> 
			<p><span>Nota: </span>
				Un departamento es a donde un grupo de artículos en tu tienda pertenecerán. Ejemplo: Juguetes.
			</p>
			<form onSubmit={_pushData}>
				<div onSubmit={(e)=>e.preventDefault()}>
					<div className='row'>
						<div className='col m8 m-p-0'>
							<div className='row m-p-0'>
								<div className='input-field col s12'>
									<input name='departamento' id='departamento' value={data.departamento} onChange={(e) => setData({...data, departamento:e.target.value})} type='text' style={{background:'#FFFFFF',border:'1px solid #ebebeb',paddingLeft:5}} required/>
									<label htmlFor='departamento'>Nombre departamento</label>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col m8 m-p-0'>
							<div className='row m-p-0'>
								<div className='input-field col s12'>
									Descripción corta <br />
									<textarea name='descripcion' id='descripcion' value={data.descripcion} onChange={(e) => setData({...data, descripcion:e.target.value})} className='materialize-textarea' style={{minHeight:200,background:'#FFFFFF',border:'solid 1px #ebebeb',paddingLeft:5}} required />
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col m8'>
							<label style={{cursor:'pointer', fontSize:'12px', fontWeight:'900'}}>
								<div style={{width:'100%'}}>
									<img alt='img' src={foto?URL.createObjectURL(foto):data.img?data.img:'https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/subirImg.jpg'} style={{margin:'0 auto', maxHeight:'100%', maxWidth:'100%', objectFit:'contain',textAlign:'left',}} />
								</div>
								<input  onChange={(e)=>{setFoto(e.target.files[0])}} type="file" style={{display:'none', height:'500px', width:'800px',}}/>
							</label>
						</div>
					</div>
				</div>
				<div className='row' style={{position:'relative', height:50}}>
					<div className='' style={{position:'absolute',right:20}}>
						<button className='btn' style={{backgroundColor:'#4966B1',color:'white',height:50,width:150}}>Siguiente</button>
					</div>
					<div className='' style={{position:'absolute',left:20}}>
						<button className='btn'  onClick={(e)=>_return(e)} style={{backgroundColor:'#4966B1',color:'white',height:50,width:150}}>Anterior</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default StepThree;
