import React, { useEffect, useState } from 'react';
import { axios } from '../../config'
import M from 'materialize-css'

const SaldosPorPagar = () => {

	const [data, setData] = useState({mes:'', cuenta_destino:'', saldo:{proveedor: {company_name:'', cuenta_bancaria:{banco:'', numero_tarjeta:''}}}})
	const [saldosPorPagar, setSaldosPorPagar] = useState([])

	useEffect(()=>{
		axios.get('/api/saldos_por_pagar')
		.then((r)=>{
			setSaldosPorPagar(r.data)
		})
		.catch(e=>alert(e))

		M.Datepicker.init(document.querySelectorAll('.datepicker'), {format:'yyyy-mm-dd'});
		M.Modal.init(document.querySelectorAll('.modal'), {});


	},[])
	
	useEffect(()=>{
		M.FormSelect.init(document.querySelectorAll('select'), {});
	},[data.saldo])

	const registrar_pago = (e) =>{

		e.preventDefault()

		const fecha = document.querySelector('#modal_registrar_pago_fecha').value
		axios.post('/api/registrar_pago', {...data, fecha})
		.then(r=>{
			console.log(r.data)
			const nuevo_saldo = r.data.saldo
			if(nuevo_saldo.saldo_listo>0){
				setSaldosPorPagar(saldosPorPagar.map(s=>s.id===nuevo_saldo.id?{...s, saldo_listo:nuevo_saldo.saldo_listo}:s))
			}else{
				setSaldosPorPagar(saldosPorPagar.filter(s=>s.id!==nuevo_saldo.id))
			}
			document.querySelector('#modal_registrar_pago_fecha').value='';
			setData({mes:'', cuenta_destino:'', monto:'', cuenta_origen:'', referencia:'', saldo:{proveedor: {company_name:'', cuenta_bancaria:{banco:'', numero_tarjeta:''}}}})
		})
		.catch(e=>alert(e))
		const instance = M.Modal.getInstance(document.querySelector('.modal'));
		instance.close()


	}

	return(
		<>
		<div id="modal-registrar-pago" className="modal">
			<div className="modal-content">
				<h4>Registrar Pago a {data.saldo.proveedor.company_name}</h4>
				<div className="row">
					<form onSubmit={registrar_pago} className="col s12 m12">
						<div className="row">
							<div className="input-field col s12">
								<input id="modal_registrar_pago_fecha" type="text" className="datepicker" required/>
								<label htmlFor="modal_registrar_pago_fecha">Fecha</label>
							</div>
							<div className="input-field col s12">
								<input value={data.monto} onChange={(e)=>setData({...data, monto: e.target.value})} id="modal_registrar_pago_monto" type="number" required/>
								<label htmlFor="modal_registrar_pago_monto">Monto</label>
							</div>
							<div className="input-field col s12">
								<input value={data.cuenta_origen} onChange={(e)=>setData({...data, cuenta_origen: e.target.value})} id="modal_registrar_pago_cuenta_origen" type="text" required/>
								<label htmlFor="modal_registrar_pago_cuenta_origen">Cuenta Origen</label>
							</div>
							<div className="input-field col s12">
								<select value={data.cuenta_destino} onChange={(e)=>setData({...data, cuenta_destino: e.target.value})} required>
									<option value="" disabled>Seleccionar una cuenta</option>
									<option value={`${data.saldo.proveedor.cuenta_bancaria.banco}-${data.saldo.proveedor.cuenta_bancaria.numero_tarjeta}`}>{data.saldo.proveedor.cuenta_bancaria.banco}-{data.saldo.proveedor.cuenta_bancaria.numero_tarjeta}</option>
								</select>
								<label>Cuenta Destino</label>
							</div>
							<div className="input-field col s12">
								<label htmlFor="textarea1">Referencia</label>
								<textarea value={data.referencia} onChange={(e)=>setData({...data, referencia: e.target.value})} id="textarea1" className="materialize-textarea" style={{height:100, border:'1px solid grey'}} required/>
							</div>
							<div className="input-field col s12">
								<select value={data.mes} onChange={(e)=>setData({...data, mes: e.target.value})} required>
									<option value="" disabled>Seleccionar un mes</option>
									<option value="Enero">Enero</option>
									<option value="Febrero">Febrero</option>
									<option value="Marzo">Marzo</option>
									<option value="Abril">Abril</option>
									<option value="Mayo">Mayo</option>
									<option value="Junio">Junio</option>
									<option value="Julio">Julio</option>
									<option value="Agosto">Agosto</option>
									<option value="Septiembre">Septiembre</option>
									<option value="Octubre">Octubre</option>
									<option value="Noviembre">Noviembre</option>
									<option value="Diciembre">Diciembre</option>
								</select>
								<label>Mes</label>
							</div>
						</div>
						<button className="waves-effect waves-green btn-flat black white-text">Registrar Pago</button>
					</form>
				</div>
			</div>
		</div>
		<div className='card-panel'>
			<h4>Saldos por pagar</h4>
			<table>
				<thead>
					<tr>
						<th>ID</th>
						<th>Reseller</th>
						<th>Saldo Listo</th>
						<th>Opciones</th>
					</tr>
				</thead>

				<tbody>
				{saldosPorPagar.map((s,i)=>
					<tr key={i}>
						<td>{s.id}</td>
						<td>{s.proveedor.company_name}</td>
						<td>{s.saldo_listo}</td>
						<td>
						{s.proveedor.cuenta_bancaria?
							<a className="waves-effect waves-light btn modal-trigger" href="#modal-registrar-pago" onClick={()=>setData({...data, saldo:s})}>Registrar Pago</a>:
							<a className="waves-effect waves-light btn" href="#!">Pendiente Cuenta-Bancaria</a>
						}
						</td>
					</tr>
				)}
				</tbody>
			</table>
		</div>
		</>
	)
}

export default SaldosPorPagar
