import React, { useState, useEffect } from 'react'
import { axios } from '../../config'

const StockRacks = () => {

	const [granel, setGranel]=useState([])
	const [search, setSearch]=useState('')

	useEffect(()=>{
		axios.get('/api/productInGranel')
		.then(r=>{
			setGranel(r.data)
                 })
                 .catch(r=>alert(r))
	},[])
	
	const _rackToExcel = () => {
		const busqueda=granel.filter(i=>i.codigo.includes(search)||i.caja_id.includes(search)||i.departamento.toLowerCase().includes(search)||i.categoria.toLowerCase().includes(search))
		axios.post('/api/productInGranel',{productos:busqueda})
		.then(r=>{
			var a = document.createElement("a");
			a.href = r.data.file;
			a.download = r.data.name;
			document.body.appendChild(a);
			a.click();
			a.remove();
		})
		.catch(r=>alert(r))
	}

	return (
			<div className="row">
				<div className="card-panel">
				<h4>Area de Granel</h4>
				<div className="row">
				<div className="col s6">
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field">
								<input value={search} onChange={e=>setSearch(e.target.value)} id="search" type="search" required />
								<label className="label-icon" htmlFor="search"><i className="material-icons">search</i></label>
								<i className="material-icons">close</i>
							</div>
						</form>
					</div>
				</nav>
				</div>
				<div className="col s6">
					<a href="#!" className="waves-effect waves-light btn right" onClick={_rackToExcel}>Exportar</a>
				</div>
				</div>
				<div className="table-wrapper">
				<table className="highlight">
					<thead>
						<tr>
							<th>#</th>
							<th>Caja</th>
							<th>Código</th>
							<th>Nombre</th>
							<th>Departamento</th>
							<th>Categoria</th>
							<td>Piezas por Display</td>
							<td>Cantidad de Displays</td>
							<td>Total de productos/piezas</td>
						</tr>
					</thead>
					<tbody>
					{granel.filter(i=>i.codigo.includes(search)||i.caja_id.includes(search)||i.departamento.toLowerCase().includes(search)||i.categoria.toLowerCase().includes(search)).map((m,i)=>
						<tr key={i}>
							<td>{i+1}</td>
							<td>{m.caja_id}</td>
							<td>{m.codigo}</td>
							<td>{m.nombre_del_producto}</td>
							<td>{m.departamento}</td>
							<td>{m.categoria}</td>
							<td>{m.display}</td>
							<td>{m.cantidadDisplays}</td>
							<td>{m.cantidad}</td>
						</tr>
					)}
					</tbody>
				</table>
				</div>
				</div>
			</div>
	)
}
export default StockRacks
