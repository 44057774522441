import React, { useEffect, useState } from 'react';
import { axios } from '../../config';
import { useParams,Link, useHistory} from 'react-router-dom';
import M from 'materialize-css'
import FileUploadWithPreview from 'file-upload-with-preview';

const EditDepto = () => {

	const [catEdit, setcatEdit] =  useState();
	const [edit, setEdit] =  useState(false);
	const {departamento_id} = useParams()
	const [foto, setFoto] = useState({})
	const [departamento,setDepartamento] = useState({departamento:'',categorias:[],descripcion:'',img:''})
	const [catInput, setCatInput] = useState({categoria:'',departamento_id:departamento_id})
	const history = useHistory()
	
	useEffect(()=>{	
		axios.get(`/api/departamento/${departamento_id}`).then((r) => {
			setDepartamento(r.data)
			M.Materialbox.init(document.querySelectorAll('.materialboxed'), {});	
	}).catch(r=>alert(r))
	},[])

	const _pushData = (e) =>{
		e.preventDefault()
		const dataDep = new FormData()
		dataDep.append('_method', 'PUT')
		dataDep.append('data', JSON.stringify(departamento))
		dataDep.append('files', foto);

		axios.post('/api/departamento',dataDep)
		.then((r) => {
			//setLoader(false)
			setDepartamento(r.data)
			M.toast({html: 'Departamento Actualizado!'})			
		})
		.catch(r=>{
			alert(r)
			//setLoader(false)
		})
	}

	const _clearPicture = () =>{
		axios.put(`/api/departamento/${departamento_id}`)
	}

	const _softDeptoDelete = (e) =>{
		e.preventDefault();
	   	if(window.confirm("¿Estas seguro de eliminar este departamento?")){
			axios.delete(`/api/departamento/${departamento_id}`)
			.then((r) => {
				history.push('/Departamentos')	
			})
			.catch(r=>{
				alert(r)
				//setLoader(false)
			})
		}
	}
  
	const _pushCat = () => {
		axios.post('/api/categoria',catInput)
		.then((r) => {
			setDepartamento({...departamento, categorias:[...departamento.categorias,r.data]})
			setCatInput({categoria:'',departamento_id:departamento_id})
		})
		.catch(r=>alert(r))
	}

	const editCat = (cat) => {
		setEdit(true)
		setcatEdit(cat)
	}
	const updateCat = () => {
		axios.put('/api/categoria',catEdit).
		then((r) => {
			setEdit(false)
			setDepartamento({...departamento, categorias:departamento.categorias.map((cat,i)=>cat.id === catEdit.id? r.data:cat)})
			M.toast({html:'Se ha actualizado la categoria'})
		})
		.catch(r=>alert(r))
	}

	const updateCatCancel = () => {
		setEdit(false)
	}

	/**
	  * @param {object} categoria
	  */
	const _destroya = (categoria) => {
		if(window.confirm('seguro que desea eliminar la categoria?')){
			axios.delete( `/api/categoria/${categoria.id}`)
			.then(r=>{
				setDepartamento({...departamento, categorias:departamento.categorias.filter(c=>c.id!==categoria.id)})
			})
			.catch(r=>alert(r))
		}
	}
	
	const deleteImg = () =>{
		setTimeout(() => {
			setDepartamento({...departamento, img:null})
		}, 3000);
		
	}

	return (
	
	<div className='row card-panel'>
	
		<div className="col m6">
			<h4>Editar Departamento</h4>
			<form onSubmit={_pushData}>
				<div onSubmit={(e)=>e.preventDefault()}>
					<div className='row'>
						<div className='col m8 m-p-0'>
							<div className='row m-p-0'>
								<div className='input-field col s12'>
									<input name='departamento' id='departamento' value={departamento.departamento} 
									onChange={(e) => setDepartamento({...departamento, departamento:e.target.value})} type='text' required/>
								   
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col m8 m-p-0'>
							<div className='row m-p-0'>
								<div className='input-field col s12'>
									<textarea name='descripcion' id='descripcion' value={departamento.descripcion} onChange={(e) => setDepartamento({...departamento, descripcion:e.target.value})}  
									className='materialize-textarea' style={{minHeight:200, border:'solid 1px grey'}} required />
								   
								</div>
							</div>
						</div>
					</div>
					{(departamento.img)?	
						<div className='row' style={{display:'flex',flexWrap: 'wrap',alignItems:'center'}}>
							<div style={{position:'relative'}}>
								<img alt={departamento.departamento} className='materialboxed' data-caption={departamento.nombre} 
								
								src={departamento.img?(typeof(departamento.img)==='string'?departamento.img:URL.createObjectURL(departamento.img)):'https://icon-library.com/images/upload-image-icon-png/upload-image-icon-png-16.jpg'}
								style={{width:200, margin:20, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.5)'}} />

								<button className='black-text' onClick={()=>setDepartamento({...departamento,img:''})} style={{position:'absolute', top:5, right:9,border:'none',background:'transparent',cursor:'pointer'}}><i className="material-icons">clear</i></button>
							</div>
						</div>:
						<>
						<label style={{cursor:'pointer',}}>
							<div style={{margin:'0 auto',height:150}}>
								<img alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/subirImg.jpg'
								style={{margin:'0 auto' ,height:150, objectFit:'contain',}} />
							</div>
							<input onChange={(e)=>{
								setFoto(e.target.files[0]);
								setDepartamento({...departamento,img:e.target.files[0]})
								}} type="file" style={{display:'none'}}/>
						</label>
						</>
					}
					<div style={{position:'relative'}}>
						<button className='btn waves-effect waves-light'>Actualizar Departamento</button>
						<button  onClick={(e)=>_softDeptoDelete(e)} className='btn waves-effect waves-light red margin-left-10'>Eliminar Departamento</button>
						<Link to='/Departamentos' className='btn waves-effect waves-light bold' style={{right:10, position:'absolute'}}>Regresar</Link>
					</div>
				</div>
			</form>
		</div>
		
		<div className="col m6">   
			<h5>Categorias de {departamento.departamento} </h5>
			{edit ?
			<div className='row'>
				<div className='col m8 input-field'>
					<input name='categoria'  
						id='categoria' type='text' className='validate'
						value={catEdit.categoria}
						onChange={(e)=>setcatEdit({...catEdit,categoria:e.target.value})}
					/>
					<label htmlFor='categoria'>Editar Categoria</label>
				</div>
				<div className='col m4'>
					<div style={{margin:0, padding:'5px 0'}}>
						<a href='#!' onClick={updateCat} className='btn waves-effect waves-light'>Actualizar</a>
					</div>
					<div style={{margin:0, padding:'5px 0'}}>
						<a href='#!' onClick={updateCatCancel} className='btn waves-effect waves-light red'>&nbsp;&nbsp;Cancelar&nbsp;&nbsp;</a>
					</div>
				</div>
			</div> 
			: 
			<div className='row'>
				<div className='col m8 input-field'>
					<input name='categoria' value={catInput.categoria} onChange={(e) => setCatInput({...catInput, categoria:e.target.value})} id='categoria' type='text' className='validate' />
					<label htmlFor='categoria'>Nueva Categoria</label>
				</div>
				<div className='col m4' style={{fontWeight:'800!important'}}>
					<a href='#!' onClick={_pushCat} className='btn waves-effect waves-light' 
						style={{fontWeight:'800!important'}}>Agregar</a>
				</div>
			</div>
			}  
			<div className='col'>
				{departamento.categorias.map((cat,i)=> 
				<div key={i} style={{position:'relative', display:'inline-block'}}>
				<a href='#!' key={cat.id} onClick={(e)=>editCat(cat)} className='waves-effect waves-light btn grey darken-4' style={{display:'flex',borderRadius:20, margin:'4px 2px', textTransform:'none', fontSize:12, letterSpacing:'none'}}>
					<i className='material-icons'>create</i>{cat.categoria} 
				</a>
			<i onClick={()=>_destroya(cat)} className="material-icons" style={{position:'absolute', top:-5, right:0, background:'grey',zIndex:1, borderRadius:50,color:'white', fontSize:18, cursor:'pointer'}}>clear</i>
				</div>
				)}
			</div>
		</div>
	</div>
	);
};

export default EditDepto;
