/**
  * @params time_string [String]
  * @return minutesDifference [Number]
  */
export const minDiff = (dateString) => {

	const pastTime = new Date(dateString);
	const currentTime = new Date();

	const timeDifferenceMs = currentTime - pastTime;
	const minutesDifference = Math.floor(timeDifferenceMs / 60000);
	return minutesDifference
}

export const getDate = (dateString) => {
	const date = new Date(dateString)
	return date.toDateString()
}
