import React, { useState, useEffect } from 'react'
import { axios } from '../../config'

const MissingBoxes = () => {

	const [missingBoxes, setMissingBoxes]=useState([])

	useEffect(()=>{
		axios.get('/api/missing')
		.then(r=>{
			setMissingBoxes(r.data)
		})
		.catch(r=>alert(r))
	},[])

	const _push = (p) => {
		axios.put('/api/boxReceived',{caja_id:p.caja_id, contenedor_id:p.contenedor_id})
		.then(r=>{
			const miss=[...missingBoxes]
			miss.splice(miss.indexOf(p), 1)
			setMissingBoxes(miss)
		})
		.catch(r=>alert(r))

	}

	return (
			<div className="row">
				<div className="card-panel">
				<h4>Recibir Cajas</h4>
				<small>(disponible solo para el ultimo contenedor)</small>
				<table className="highlight">
					<thead>
						<tr>
							<th>#</th>
							<th>Contenedor</th>
							<th>Caja</th>
							<th>Recibir</th>
						</tr>
					</thead>
					<tbody>
					{missingBoxes.map((m,i)=>
						<tr key={i}>
							<td>{i+1}</td>
							<td>{m.contenedor}</td>
							<td>{m.caja_id}</td>
							<td>
								<a href="#!" onClick={()=>_push(m)} className="waves-effect waves-light btn">Recibir</a>
							</td>
						</tr>
					)}
					</tbody>
				</table>
				</div>
			</div>
	)
}
export default MissingBoxes
