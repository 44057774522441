import React, { useEffect, useState } from 'react';
import { axios } from '../../config'
import M from 'materialize-css'

const EmailConfig = () => {

	const [data, setData] = useState({host:'', port:'', encryption:'', user_name:'', password:'', sender_name:'', sender_email:''})

	useEffect(()=>{
		axios.get('/api/emailconfigwebsite')
		.then(r=>{
			setData(r.data)
			M.updateTextFields()
		})
		.catch(r=>alert(r))
	},[])

	const _pushData = (e) => {
		e.preventDefault()
		axios.put('/api/emailconfigwebsite', data)
		.then(r=>{
			setData(r.data)
			M.toast({html:'Configuración actualizada!!'})
		})
		.catch(r=>alert(r.response.data.message))

	}

	return (
		<div className='card-panel'>
			<h4>Email Config</h4>
			<div className="row">
				<form onSubmit={_pushData} className="col s12">
					<div className="row">
						<div className="input-field col s6">
							<input className="validate" value={data.host} onChange={(e)=>setData({...data, host:e.target.value})} id="host" type="text" required/>
							<label htmlFor="host">Host</label>
						</div>
					</div>
					<div className="row">
						<div className="input-field col s6">
							<input value={data.port} onChange={(e)=>setData({...data, port:e.target.value})} id="port" type="number" className="validate" required/>
							<label htmlFor="port">Port</label>
						</div>
					</div>
					<div className="row">
						<div className="input-field col s6">
							<input value={data.encryption} onChange={(e)=>setData({...data, encryption:e.target.value})} id="encryption" type="text" className="validate" required/>
							<label htmlFor="encryption">Encryption</label>
						</div>
					</div>
					<div className="row">
						<div className="input-field col s6">
							<input value={data.user_name} onChange={(e)=>setData({...data, user_name:e.target.value})} id="user_name" type="text" className="validate" required/>
							<label htmlFor="user_name">User Name</label>
						</div>
					</div>
					<div className="row">
						<div className="input-field col s6">
							<input value={data.password} onChange={(e)=>setData({...data, password:e.target.value})} id="password" type="text" className="validate" required/>
							<label htmlFor="password">Password</label>
						</div>
					</div>
					<div className="row">
						<div className="input-field col s6">
							<input value={data.sender_name} onChange={(e)=>setData({...data, sender_name:e.target.value})} id="sender_name" type="text" className="validate" required/>
							<label htmlFor="sender_name">Sender Name</label>
						</div>
					</div>
					<div className="row">
						<div className="input-field col s6">
							<input value={data.sender_email} onChange={(e)=>setData({...data, sender_email:e.target.value})} id="sender_email" type="text" className="validate" required/>
							<label htmlFor="sender_email">Sender Email</label>
						</div>
					</div>
					<button className='btn waves-effect waves-light'>Guardar</button>
				</form>
			</div>
		</div>
	)
}

export default EmailConfig
