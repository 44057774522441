import React, { useEffect, useState } from 'react';
import { axios } from '../../config'
import M from 'materialize-css'

const SaldosPagados = () => {

	const [saldosPagados, setSaldosPagados] = useState([])

	useEffect(()=>{
		axios.get('/api/saldos_pagados')
		.then((r)=>{
			setSaldosPagados(r.data)
		})
		.catch(e=>alert(e))

		M.Datepicker.init(document.querySelectorAll('.datepicker'), {format:'yyyy-mm-dd'});

	},[])


	return(
		<>
		<div className='card-panel'>
			<h4>Historial Saldos Pagados</h4>
			<table>
				<thead>
					<tr>
						<th>ID</th>
						<th>Fecha</th>
						<th>Monto</th>
						<th>Cuenta Origen</th>
						<th>Cuenta Destino</th>
						<th>Referencia</th>
						<th>Mes</th>
						<th>Reseller</th>
					</tr>
				</thead>

				<tbody>
				{saldosPagados.map((s,i)=>
					<tr key={i}>
						<td>{s.id}</td>
						<td>{s.fecha}</td>
						<td>{s.monto}</td>
						<td>{s.cuenta_origen}</td>
						<td>{s.cuenta_destino}</td>
						<td>{s.referencia}</td>
						<td>{s.mes}</td>
						<td>{s.proveedor.company_name}</td>
					</tr>
				)}
				</tbody>
			</table>
		</div>
		</>
	)
}

export default SaldosPagados
