import React, { useState, useEffect } from 'react';
import { axios } from '../../config'
import JoditEditor from "jodit-react";
import Loading from '../loading'

const AvisoPrivacidad = () => {

	const [data, setData] = useState({aviso_privacidad:''})
	const [loader, setLoader] = useState(true)

	useEffect(()=>{
		axios.get('/api/avisoprivacidad')
		.then(r=>{
			setData(r.data)
			setLoader(false)
		})
		.catch(r=>alert(r))
	},[])

	const _push = () => {

		const dataF = new FormData()
		dataF.append('aviso_privacidad', data.aviso_privacidad)

		axios.post('/api/avisoprivacidad', dataF)
		.then(r=>{
			alert('datos actualizados')
		})
		.catch(r=>alert(r))
	}

	return(
		<div className='card-panel'>
			<h4>Aviso de privacidad</h4>
			{loader?
			<Loading />:
			<div className="row">
				<div className="input-field col s12">
					<JoditEditor value={data.aviso_privacidad} config={{ readonly: false,askBeforePasteHTML:false }} tabIndex={1} onBlur={newContent => setData({...data, aviso_privacidad:newContent})} />
				</div>
			</div>
			}
			<a href='#!' onClick={_push} className='btn waves-effect waves-light'>Guardar</a>
		</div>
	)
}

export default AvisoPrivacidad
