import React, {useEffect, useState} from 'react';
import {axios} from '../../config';
import M from 'materialize-css';

const ComentariosList = () => {
    const [comentarios,setComentarios] = useState([])
    const [skip,setSkip] = useState(0)
    const [limit,setLimit] = useState(5)
    const [pages,setPages] = useState(0)

    useEffect(()=>{
        axios.get('/api/comentarios').then((res)=>{
          
           setPages(Math.ceil(res.data/limit))
        }).catch(e=>console.log('error',e))
    },[])
    useEffect(()=>{
        axios.get(`/api/comentario/${skip}/${limit}`).then((r)=>{
            console.log("res",r)
            setComentarios(r.data)
        }).catch(e=>console.log('error',e))
    },[skip,limit])

  
          
    const _renderStars = (calificacion = 0) =>{
        let icons =[]
        for(let i = 0;i<5;i++) icons.push(<i key={i} className="material-icons" style={{color:"#FA9C1C"}}>{(i+1<=calificacion)?'star':'star_border'}</i>)
        return icons;
    }

    const _borrar = (id) =>{
	// eslint-disable-next-line no-restricted-globals
	if (confirm("seguro que desea eliminar?")) {
	axios.post('/api/delete_comentario',{id})
	.then((r) => {
		M.toast({html:'Eliminado!'})
        	setComentarios(comentarios.filter(r=>r.id!==id))
	})
	.catch(r=>alert(r))	
	}
    }

    const _ocultar = (obj,checked) =>{
        const newcomentarios = comentarios.map(r=>r.id===obj.id?{...obj,visible_marketplace:checked}:r)
		setComentarios(newcomentarios)
		axios.post('/api/updatestatus',{id:obj.id,visible_marketplace:checked})
		.then((r) => {
			M.toast({html:'Actualizado!'})
		})
		.catch(r=>alert(r))	
    }

    const _pagination =(page) =>{
        console.log('holis',page)
        setSkip(limit*page)
    } 

    return (
        <div>
            <div className="col s12" style={{marginTop:"50px"}}>
                <h4> Evaluciones</h4>
                {comentarios.map(r=>
                    <div key={r.id} className="card s12" style={{marginTop:20,position:"relative",padding:20}}>
                    <div className='centrar-l' style={{position:'absolute', right:15,top:15}}>
                        {r.reportes_count>0&&
                        <>
                            <span className='bold'>{r.reportes_count}</span><i className="material-icons red-text">error</i>&nbsp;&nbsp;
                        </>
                        }
                        <div className="switch" style={{marginRight:30}}>
                            <label>
                                Oculto
                                <input className='vistos' type="checkbox" checked={r.visible_marketplace} onChange={(e)=>_ocultar(r,e.target.checked)}/>
                                <span className="lever"></span>
                                Visible
                            </label>
                        </div> 
                       <span className='bold'>{r.likes_comentario_count}</span>  <i className="material-icons">favorite_border</i>
                    </div>

                    <div className='row'>
                        <div className='col s12 centrar-l'>
                            <i className="material-icons">account_circle</i><span> {r.user.name} </span>
                        </div>
                        <div className="col s12 centrar-l">
                           {_renderStars(r.calificacion)}
                            <span style={{fontSize:16}} className='bold'>{r.titulo}</span>
                        </div>
                        <div style={{fontSize:14}} className='col s12'>
                            Fecha de publicación: {new Date(r.updated_at).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })} a las {new Date(r.updated_at).toLocaleTimeString('es-MX',{ hour12:true})}
                        </div>
                        <div className='col s12 bold' style={{marginTop:10}}>
                            {r.comentario}
                        </div>
                        <div className="col s12 centrar-l">
			    <a href={`https://${r.domain}/product?codigo=${r.codigo}`} className='btn waves-effect waves-light' target='_blank' rel="noreferrer">ver producto</a>
                        </div>
                        {r.reportes_count>0&&
                        <div className='red-text' style={{padding:10}}>
                            Nota: Este comentario tiene pendiente uno o mas reportes.
                        </div>
                        }
                    </div>
                    <div style={{textAlign:'right',marginTop:30}}>
                        
                        <button className="waves-effect waves-light btn red" onClick={()=>_borrar(r.id)}>Eliminar Comentario</button>
                    </div>
                </div>
                )}
                
            <ul className="pagination">
                <li className="disabled"><a href="#!"><i className="material-icons">chevron_left</i></a></li>
                <li className="active"><a onClick={()=>_pagination(0)}>1</a></li>
                <li className="active"><a onClick={()=>_pagination(1)}>2</a></li>
                <li className="waves-effect"><a href="#!"><i className="material-icons">chevron_right</i></a></li>
            </ul>
            
            </div>
        </div>
    );
};

export default ComentariosList;
