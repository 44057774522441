import React, { useEffect, useState } from 'react';
import { axios } from '../../config';
import ListaProductos from './listaProductos';
import M from 'materialize-css';
import Loading from '../loading';

const Pedidos = () => {
	const [pedidos, setPedidos] = useState([]) 
	const [list, setList] = useState([])
	const [cupon, setCupon] = useState({})
	const [pago, setPago] = useState(0)
	const [loader, setLoader] = useState(true)

	useEffect(()=>{
		axios.get('/api/pedidoslist').then((res)=>{
			setPedidos(res.data)
			setLoader(false)
		}).catch(e=>console.log("error",e))
	},[])

	const _showModal = (pedido) => {

		const instance = M.Modal.getInstance(document.querySelector('.modal'));
		instance.open();

		setList(pedido.source_object_reference.articulos)
		setPago(pedido.source_object_reference.total_pagado)
		setCupon(pedido.source_object_reference.cupon?pedido.source_object_reference.cupon:{})
	}

	const _page = (url) => {
		setLoader(true)
		axios.get(url).then((res)=>{
			setPedidos(res.data)
			setLoader(false)
		}).catch(e=>console.log("error",e))
	}

	if(loader) return (
		<div className="progress">
			<div className="indeterminate"></div>
		</div>
	)

	return (
		<>
			<ListaProductos list={list} cupon={cupon} pago={pago}/>
			{pedidos.data.length===0&&
			<div className='card-panel'>
				<center>
					<h4>Sin Pedidos</h4>
				</center>
			</div>
			}
			{pedidos.data.length>0&&
			<div className="col s12" style={{marginTop:50}}>
				<table id="table-catalogo">
					<thead>
						<tr>
							<th>Datos del cliente</th>
							<th>Direccion del envio</th>
							<th>Datos de entrega</th>
							<th>Status del pedido</th>
							<th>Status Envio</th>
							<th>Articulos</th>
						</tr>
					</thead>

					
					<tbody style={{width:'100%'}}>
					{pedidos.data.map((pedido,i)=>
						<tr key={pedido.id} style={{borderBottom:'solid 1px #ECECEC', padding:'36px 0!important', width:'100%'}}>
							<td style={{margin:0, backgroundColor:'rgba(255, 255, 255, .6)',}}> 
								{pedido.source_object_reference&&
								<div style={{color:'#000000',fontSize:'13px'}}>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>Nombre:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.envio.nombre}</div>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>Correo:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.envio.correo}</div>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>Teléfono:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.envio.telefono}</div>
								</div>
								}
							</td>
							<td style={{margin:0, backgroundColor:'rgba(255, 255, 255, .6)',}}> 
								{pedido.source_object_reference&&
								<div style={{color:'#000000',fontSize:'13px'}}>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>Calle:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.envio.calle}</div>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>Ciudad:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.envio.ciudad
										}</div>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>Colonia:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.envio.colonia}</div>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>C.P.:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.envio.cp}</div>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>Estado:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.envio.estado}</div>
								</div>
								}
							</td>
							<td style={{margin:0, backgroundColor:'rgba(255, 255, 255, .6)',}}> 
								{pedido.source_object_reference&&
								<div style={{color:'#000000',fontSize:'13px'}}>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>Pedido:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.envio.pedido_id}</div>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>No. Guía:</b> &nbsp;&nbsp;
										<a style={{color:'#000000', textDecoration:'underline',cursor:'pointer'}} href={pedido.source_object_reference.envio.guia} target="_blank">IR A GUÍA </a></div>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>Servicio:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.envio.servicio}</div>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>Descripcion:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.envio.descripcion}</div>
								</div>
								}
							</td>
							<td style={{margin:0, backgroundColor:'rgba(255, 255, 255, .6)',}}>  
								{pedido.source_object_reference&&
								<div style={{color:'#000000',fontSize:'13px'}}>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>Status:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.status}, {pedido.status}</div>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>Total:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.total_pagado}</div>
									<div style={{paddding:'10px 4px!important', border:'solid transparent', forntSize:'14px!important'}}><b style={{color:'#9b9b9b'}}>Metodo Pago:</b> &nbsp;&nbsp;
										{pedido.source_object_reference.metodo_pago}</div>
								</div>
								}
							</td>
							<td style={{margin:0, height:150}}> 
								{(pedido.source_object_reference.envio['response']!==undefined&&pedido.source_object_reference.envio.servicio!=='Recolección')&&
								<div style={{height:'100%', overflow:'auto'}}>
									{
									pedido.source_object_reference.envio.response.CompletedTrackDetails.TrackDetails.Events.length>0?pedido.source_object_reference.envio.response.CompletedTrackDetails.TrackDetails.Events.map((e,i)=>
									<div key={i} className='row' style={{fontSize:16, margin:5}}>
										<div className='col'><i className="tiny material-icons" style={{background:'#4D4D4D', color:'white', borderRadius:50}}>check</i></div>
										<div className='col'>{new Date(e.Timestamp).toLocaleString()}</div>
										<div className='col s3'>{e.Address.City}</div>
										<div className='col'>{e.EventDescription}</div>
									</div>):
									<div className='row' style={{fontSize:16, margin:5}}>
										<div className='col'><i className="tiny material-icons" style={{background:'#4D4D4D', color:'white', borderRadius:50}}>check</i></div>
										<div className='col'>{new Date(pedido.source_object_reference.envio.response.CompletedTrackDetails.TrackDetails.Events.Timestamp).toLocaleString()}</div>
										<div className='col s3'>{pedido.source_object_reference.envio.response.CompletedTrackDetails.TrackDetails.Events.Address.City}</div>
										<div className='col'>{pedido.source_object_reference.envio.response.CompletedTrackDetails.TrackDetails.Events.EventDescription}</div>
									</div>
									}
								</div>
								}
								{(pedido.source_object_reference.envio.servicio==='Recolección'&&pedido.status==='Entregado')&&
									<div style={{textAlign:'center'}}>
										<h5>Recibio: {pedido.source_object_reference.entrega.nombre}</h5>
										<img width='200' height='200' src={pedido.source_object_reference.entrega.firma} />
										<div>Firma</div>
											
									</div>
								}
							</td>
							<td style={{margin:0, backgroundColor:'rgba(255, 255, 255, .6)',}}> 
								<button className="waves-effect waves-light btn" style={{fontSize:'12px', lineHeight:.9}} onClick={()=>_showModal(pedido)}> Listado de <br></br>Productos</button>
							</td>
						</tr>
					)}
					</tbody>
				</table>
				<ul className="pagination">
					<li className="waves-effect" onClick={()=>_page(pedidos.prev_page_url??pedidos.first_page_url)}><a href="#!"><i className="material-icons">chevron_left</i></a></li>
					{pedidos.links.slice(1, -1).map((l,i)=>
						<li key={i} className={`waves-effect ${l.active?'active black':''}`} onClick={()=>_page(l.url)}><a href="#!">{l.label}</a></li>
					)}
					<li className="waves-effect" onClick={()=>_page(pedidos.next_page_url??pedidos.last_page_url)}><a href="#!"><i className="material-icons">chevron_right</i></a></li>
				</ul>
			</div>
			}
		</>
	);
};

export default Pedidos;
