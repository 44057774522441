import React, { useEffect, useState } from 'react'
import { axios } from '../../config'
import M from 'materialize-css'

const CatalogoGlobal = () => {

	const [catalogo, setCatalogo] = useState([])

	useEffect(()=>{
		axios.get('/api/catalogo_global')
		.then((r)=>{
			setCatalogo(r.data)
		})
		.catch((e)=>alert(e))

	},[])

	return(
		<div className='card-panel'>
			<h4>Catálogo global</h4>
			<table>
				<thead>
					<tr>
						<th>No</th>
						<th>Item no</th>
						<th>Name</th>
						<th>Description</th>
						<th>Category</th>
						<th>Departament</th>
						<th>Supplier/Seller</th>
						<th>Picture</th>
					</tr>
				</thead>
				<tbody>
					{catalogo.map((c,i)=>
					<tr key={i}>
						<td>{i+1}</td>
						<td>{c.codigo}</td>
						<td style={{whiteSpace:'normal', wordWrap:'break-word'}}>{c.nombre}</td>
						<td style={{whiteSpace:'normal', wordWrap:'break-word'}}>
							{c.descripcion}
						</td>
						<td>{c.categoria}</td>
						<td style={{whiteSpace:'normal', wordWrap:'break-word'}}>{c.company_name}</td>
						<td style={{whiteSpace:'normal', wordWrap:'break-word'}}>{c.departamento}</td>
						<td>
						<a href={c.url} target='_blank' rel="noreferrer">
							<img alt='picture' src={c.imagenes[0]?.url} style={{objectFit:'contain', height:100}}/>
						</a>
								
						</td>
					</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default CatalogoGlobal
