import React, { useEffect, useState } from 'react';
import Step1CompanyBasicInfo from './step1_CompanyBasicInfo'
import Step2ProductDetails from './step2_productDetails'
import Step3CompanyCompletedInfo from './step3_CompanyCompletedInfo'
import Step4ContactPerson from './step4_ContactPerson'
import Step5ExportInfo from './step5_ExportInfo'
import Step6Certificates from './step6_Certificates'
import M from 'materialize-css'
import './index.css'

const PerfilEmpresa = () => {

	const [percent, setPercent] = useState('0%')

	useEffect(()=>{
		M.AutoInit()
	},[])

	useEffect(()=>{

		/**
		  * Tab Select
		  */
		const tabs = document.querySelector(".tabs")
		const instance = M.Tabs.getInstance(tabs)

		window.scrollTo(0, 0)
		const percentNumber = parseFloat(percent.replace('%', ''))
		const step = Math.round(percentNumber/(100/6))+1
		instance.select('step'+step)


	},[percent])

	/*
	   * @params state array(6)
	   * state[0].state_name String
	   * state[0].compteted Boolean
	   */
	const _percent_calc = (states) => {

		const completedCount = states.filter(obj => obj.completed).length;
		const totalCount = states.length;
		const completedPercentage = (completedCount / totalCount) * 100;
		setPercent(completedPercentage+'%')

	}


	return(
		<div className='card-panel'>
		<div style={{display:'flex', alignItems:'center', marginBottom:50}}>
			<h4>Manage Company Information</h4>
			{percent==='100%'&&
			<i className="large material-icons green-text" style={{marginLeft:10}}>check_circle</i>
			}
		</div>

		{percent!=='100%'&&
		<div style={{display:'flex', alignItems:'center'}}>
			<div className="progress" style={{height:20, backgroundColor:'#e8e8e8'}}>
				<div className="determinate" style={{width: percent, backgroundColor:'green'}}></div>
				{Array(6).fill(null).map((a,i)=>
				<div key={i} style={{background:'white', width:5, height:20, position:'absolute', left:`${((100/6)*(i+1))}%`}}></div>
				)}
			</div>
		</div>
		}
			<div className="row">
				<div className="col s12" style={{marginBottom:50, padding:0}}>
					<ul className="tabs">
						<li id='basic-info' className="tab col s3" style={{width:'16.66%'}}><a href="#step1">Company basic info</a></li>
						<li id='product-details' className="tab col s3" style={{width:'16.66%'}}><a href="#step2">Product details</a></li>
						<li id='complete-info' className="tab col s3" style={{width:'16.66%'}}><a href="#step3">Company complete info</a></li>
						<li id='contact-person' className="tab col s3" style={{width:'16.66%'}}><a href="#step4">Contact person</a></li>
						<li id='export-info' className="tab col s3" style={{width:'16.66%'}}><a href="#step5">Export info</a></li>
						<li id='certificate-center' className="tab col s3" style={{width:'16.66%'}}><a href="#step6">Certificate center</a></li>
					</ul>
				</div>
	
				<div id="step1" className="col s12">
					<Step1CompanyBasicInfo percent_calc={_percent_calc}/>
				</div>

				<div id="step2" className="col s12">
					<Step2ProductDetails percent_calc={_percent_calc}/>
				</div>
				<div id="step3" className="col s12">
					<Step3CompanyCompletedInfo percent_calc={_percent_calc}/>
				</div>
				<div id="step4" className="col s12">
					<Step4ContactPerson percent_calc={_percent_calc}/>
				</div>
				<div id="step5" className="col s12">
					<Step5ExportInfo percent_calc={_percent_calc}/>
				</div>
				<div id="step6" className="col s12">
					<Step6Certificates percent_calc={_percent_calc}/>
				</div>
			</div>
		</div>
	)
	
}

export default PerfilEmpresa
