import React, { useState, useEffect } from 'react'
import { axios } from '../../config'

const MissingBoxes = () => {

	const [missingBoxes, setMissingBoxes]=useState([])
	const [search, setSearch]=useState('')

	useEffect(()=>{
		axios.get('/api/missingBoxes')
		.then(r=>{
			setMissingBoxes(r.data)
		})
		.catch(r=>alert(r))
	},[])

	const _rackToExcel = () => {
		const busqueda=missingBoxes.filter(m=>m.contenedor.toLowerCase().includes(search.toLowerCase())).map(m=>({contenedor:m.contenedor,caja_id:m.caja_id,codigo:m.codigo,nombre:m.nombre_del_producto,cantidad:m.cantidad,display:m.display}))
		axios.post('/api/missingBoxesToExcel',{productos:busqueda})
		.then(r=>{
			var a = document.createElement("a");
			a.href = r.data.file;
			a.download = r.data.name;
			document.body.appendChild(a);
			a.click();
			a.remove();
		})
		.catch(r=>alert(r))
	}

	return (
			<div className="row">
				<div className="card-panel">
				<h4>Cajas faltantes</h4>
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field">
								<input value={search} onChange={e=>setSearch(e.target.value)} id="search" type="search" required placeholder='Contenedor'/>
								<label className="label-icon" htmlFor="search"><i className="material-icons">search</i></label>
								<i className="material-icons">close</i>
							</div>
						</form>
					</div>
				</nav>
				<a href="#!" className="waves-effect waves-light btn right" onClick={_rackToExcel}>Exportar</a>
				<table className="highlight">
					<thead>
						<tr>
							<th>#</th>
							<th>Contenedor</th>
							<th>caja_id</th>
							<th>Numero Caja</th>
							<th>Código</th>
							<th>Nombre del producto</th>
							<th>Cantidad</th>
							<th>Display</th>
						</tr>
					</thead>
					<tbody>
					{missingBoxes.filter(m=>m.contenedor.toLowerCase().includes(search.toLowerCase())).map((m,i)=>
						<tr key={i}>
							<td>{i+1}</td>
							<td>{m.contenedor}</td>
							<td>{m.caja_id}</td>
							<td>{m.numero_caja}</td>
							<td>{m.codigo}</td>
							<td>{m.nombre_del_producto}</td>
							<td>{m.cantidad}</td>
							<td>{m.display}</td>
						</tr>
					)}
					</tbody>
				</table>
				</div>
			</div>
	)
}
export default MissingBoxes
