import React, { useEffect, useState } from 'react';
import {axios} from '../../config'
import { Link, useHistory } from 'react-router-dom'

const Cotizaciones = () => {

	const [cotizaciones, setCotizaciones] = useState([])
	const history = useHistory()

	useEffect(()=>{
		axios.get('/api/seller_quotes')
		.then(r=>{
			setCotizaciones(r.data)
		})
		.catch(r=>alert(r))
		
	},[])

	const _cotizacion = (cotizacion_id) => {
		history.push(`/cotizacion/${cotizacion_id}`)
	}

	return (
		<div className='card-panel'>
			<h4>Cotizaciones</h4>
			<table>
				<thead>
					<tr>
						<th>Cotizacion</th>
						<th>Producto Item_no</th>
						<th>Producto</th>
						<th>Cantidad</th>
						<th>Cotizacion destino</th>
						<th>Fecha</th>
					</tr>
				</thead>

				<tbody>
					{cotizaciones.map((c,i)=>
					<tr key={i} style={{cursor:'pointer'}} onClick={()=>_cotizacion(c.id)}>
						<td>Quote-{String(c.id).padStart(4, 0)}</td>
						<td>{c.item_no}</td>
						<td>{c.object_ref.producto?.nombre}</td>
						<td>{c.cantidad}</td>
						<td>{c.object_ref.cotizacion?.country}</td>
						<td>{new Date(c.created_at).toLocaleString('es-MX', { timeZone: "America/Mexico_City" })}</td>
					</tr>
					)}
				</tbody>
			</table>
		</div>
	)

}

export default Cotizaciones
