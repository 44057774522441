import React, { useEffect, useState } from 'react';
import { axios } from '../../../config'
import M from 'materialize-css'

const Step6Certificates = ({percent_calc}) => {

	const [data, setData] = useState({certificate_name:''})
	const [certificates, setCertificates] = useState([])

	useEffect(()=>{
		axios.get('/api/get_seller')
		.then(r=>{
			setCertificates(r.data.certificates)
		})
		M.Modal.init(document.querySelectorAll('.modal'), {});
	},[])

	const _send = (e) => {
		e.preventDefault()
		console.log(data)
		/*
		   * get images
		   */
		let certificate_photo = document.getElementById('certificate_photo').files[0]
		const formData = new FormData();
		formData.append('certificate_name', data.certificate_name)
		formData.append('certificate_photo', certificate_photo)
		axios.post('/api/seller_certificate_center', formData)
		.then(r=>{
			setCertificates(r.data.certificates)
			percent_calc(r.data.states)
			M.toast({html:'Updated!'})

		})
	}

	const _delete= (id) => {
		axios.delete(`/api/seller_certificate_center/${id}`)
		.then(r=>{
			setCertificates(r.data.certificates)
		})
	}

	return(
		<>
			<div id="modal_new_certificate" className="modal">
				<div className="modal-content">
					<h4>New Certificate</h4>
					<form onSubmit={_send}>
						<div className="row">
							<div className="input-field col s12">
								<input value={data.certificate_name||''} onChange={(e)=>setData({...data, certificate_name:e.target.value})} id="certificate_name" type="text" className="validate" />
								<label htmlFor="certificate_name">Certificate Name</label>
							</div>
							<div className="file-field input-field col s12">
								<div className="btn">
									<span>Certificate Photo</span>
									<input type="file" id='certificate_photo'/>
								</div>
								<div className="file-path-wrapper">
									<input className="file-path validate" type="text" />
								</div>
							</div>
						</div>
						<button className='btn waves-effect waves-light black right modal-close'>Save</button>
					</form>
				</div>
			</div>

			<div className="row">
				<div className="col s12" style={{background:'grey', color:'white'}}>
					<div>Certificate Center</div>
				</div>
			</div>
			<div className="row">
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Photo</th>
							<th>Action</th>
						</tr>
					</thead>

					<tbody>
					{certificates.map((c,i)=>
						<tr key={i}>
							<td>{c.name}</td>
							<td style={{whiteSpace:'normal', wordWrap:'break-word'}}><a href={c.url} target='_blank' rel='noreferrer'>{c.url}</a></td>
							<td>
								<i className="material-icons" style={{cursor:'pointer'}} onClick={()=>_delete(i)}>delete</i>
							</td>
						</tr>
					)}
					</tbody>
				</table>
			</div>

			<a className="waves-effect waves-light btn modal-trigger right" href="#modal_new_certificate">New Certificate</a>

		</>
	)
	
}

export default Step6Certificates
