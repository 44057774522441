import React, { useEffect, useState } from 'react'
import { axios } from '../../config'

const Defects = () => {

	const [defectos, setDefectos] = useState([])

	useEffect(()=>{
		axios.get('/api/defects')
		.then(r=>{
			setDefectos(r.data)
		})
	},[])

	const _toExcel = () => {
		axios.get('/api/excelDefects')
		.then(r=>{
			var a = document.createElement("a");
			a.href = r.data.file;
			a.download = r.data.name;
			document.body.appendChild(a);
			a.click();
			a.remove();
		})
		.catch(r=>alert(r))
	}

	return (
		<div className="card-panel">
			<h4>Producto Defectuoso</h4>
			<button onClick={_toExcel} className="btn waves-effect waves-light right">Exportar</button>
			<div className="table-wrapper">
			<table className="highlight">
				<thead>
					<tr>
						<th>#</th>
						<th>Caja</th>
						<th>Código</th>
						<th>Defecto</th>
						<th>Responsable</th>
					</tr>
				</thead>
				<tbody>
				{defectos.map((d,i)=>
					<tr key={i}>
						<td>{i+1}</td>
						<td>{d.caja_id}</td>
						<td>{d.codigo}</td>
						<td>{d.defecto}</td>
						<td>{d.user.name}</td>
					</tr>
				)}
				</tbody>
			</table>
			</div>
		</div>
	)
}

export default Defects
