import React, { useEffect, useState } from 'react';
import { axios } from '../../../config';
import { useHistory } from 'react-router-dom';
import FacebookConfig from '../../configs/redes/facebook';
import InstagramConfig from '../../configs/redes/instagram'
import YoutubeConfig from '../../configs/redes/youtube'

const StepFive = () => {

	const history = useHistory();
	const [config, setConfig] = useState({icon_facebook:'',url_facebook:'', icon_instagram:'', url_instagram:'', icon_youtube:'',url_youtube:'',configs:{visible_redes:{facebook:1, instagram:1, youtube:1}}})
	const [loader, setLoader] = useState(false)
	
	useEffect(()=>{
		axios.get('/api/configwebsite')
		.then((r) => {
			setConfig(r.data)
		})
		.catch(r=>alert(r))
	},[])

	const _return = (e) => {
		e.preventDefault();
		axios.put('/api/savestatus',{config_store:{"wizard_config":{"installed":0,"status":"step_two"}}}).then((res)=>{
			history.push("/")
			window.location.reload()
		}).catch(e=>console.log("error",e))
	}

	const _pushData = (e) =>{

		e.preventDefault()
		let r  = new RegExp(/^(http|https):\/\/[^ "]+$/);
		let validos= true
		const {icon_facebook,url_facebook,icon_instagram,url_instagram,icon_youtube,url_youtube,configs} = {...config}
		const conf = {icon_facebook,url_facebook,icon_instagram,url_instagram,icon_youtube,url_youtube,configs}
		
		   
		if(config.configs.visible_redes.facebook){
			if(!config.icon_facebook){
				alert("Es necesario que selecciones un icono y url de facebook")
				validos = false;
				return;
			}  
			if(!r.test(config.url_facebook)){
				alert("Verifique que sea correcta la url de la red social facebook"); 
				validos=false;
				return;
			}
		}
		if(config.configs.visible_redes.instagram){
			if(!config.icon_instagram){
				alert("Es necesario que selecciones un icono y url de instagram")
				validos = false;
				return;
			}  
			if(!r.test(config.url_instagram) ){
				alert("Verifique que sea correcta la url de la red social instagram"); 
				validos=false;
				return;
			}
		}
		if(config.configs.visible_redes.youtube){
			if(!config.icon_youtube ){
				alert("Es necesario que selecciones un icono y url de youtube")
				validos = false;
				return;
			} 
			if(!r.test(config.url_youtube) ){
				alert("Verifique que sea correcta la url de la red social youtube"); 
				validos=false;
				return;
			}
		}
	 
		if(validos){
			setLoader(true)
			axios.put('/api/updateredes',conf).then((response)=>{
				axios.put('/api/savestatus',{config_store:{"wizard_config":{"installed":1,"status":"step_six"}}}).then((res)=>{   
				setLoader(false)
				history.push("/")
				window.location.reload()
		}).catch(e=>console.log("error",e))
			}).catch(e=>console.log(e))
		}
	}

	return (
		<div>
			<form onSubmit={_pushData}>
				<div onSubmit={(e)=>e.preventDefault()}>
					<div className='padding-20' style={{position:'relative'}}>
						<div className="row">
							<h5>Redes Sociales</h5>
							<h6>Todo sus cambios o ajustes se verán reflejados al presionar el botón de Finalizar en la parte inferior</h6> 
							<div style={{fontSize:'14px', fontWeight:'900!important'}}> Estas redes sociales serán agregadas al sitio web para su despliegue en el e-commerce </div>
							<div style={{fontSize:'12px', fontWeight:'900!important', color:'red'}}> Nota: Verifica que previamente cada url incluya http:// </div>
							<div className='col s12'>
								<div style={{paddingTop:'20px'}}> ¿Con cuáles redes sociales cuentas? <br></br> </div>
							</div>
							<div className="col s12">
								<div className="switch" style={{margin:'0 auto', textAlign:'center'}}>
									<label style={{fontWeight:600,}}>
										FACEBOOK: Oculto
										<input type="checkbox" checked={config.configs.visible_redes.facebook} 
										onChange={(e)=>setConfig({...config, configs:{...config.configs, visible_redes:{...config.configs.visible_redes, facebook:e.target.checked}}})} />
										<span className="lever"></span>
										Visible 
									</label>
								</div>
								{config.configs.visible_redes.facebook?
									<FacebookConfig setConfig={setConfig} config={config}/>
									:
									<div>&nbsp;</div>
								}
							</div>
							
							<div className="col s12">
								<div className="switch" style={{margin:'0 auto', textAlign:'center'}}>
									<label style={{fontWeight:600,}}>
										INSTAGRAM: Oculto
										<input type="checkbox" checked={config.configs.visible_redes.instagram} 
										onChange={(e)=>setConfig({...config, configs:{...config.configs, visible_redes:{...config.configs.visible_redes, instagram:e.target.checked}}})} />
										<span className="lever"></span>
										Visible 
									</label>
								</div>
								{config.configs.visible_redes.instagram?
									<InstagramConfig setConfig={setConfig} config={config}/>
									:
									<div>&nbsp;</div>
								}
							</div>
							
							<div className="col s12">
								<div className="switch" style={{margin:'0 auto', textAlign:'center'}}>
									<label style={{fontWeight:600,}}>
										YOUTUBE: Oculto
										<input type="checkbox" checked={config.configs.visible_redes.youtube} 
										onChange={(e)=>setConfig({...config, configs:{...config.configs, visible_redes:{...config.configs.visible_redes, youtube:e.target.checked}}})} />
										<span className="lever"></span>
										Visible 
									</label>
								</div>
								{config.configs.visible_redes.youtube?
									<YoutubeConfig setConfig={setConfig} config={config}/>
									:
									<div>&nbsp;</div>
								}
							</div>
							<div className="row" style={{padding:'20px'}}></div>
							{loader===true?
							<div className="progress">
								<div className="indeterminate"></div>
							</div>:
							<button className='btn' style={{backgroundColor:'#4966B1',color:'white',position:'absolute',right:20,bottom:20,height:50,width:150}}>Finalizar</button>
							}
							  
							<div className='' style={{position:'absolute',left:20,bottom:20,}}>
								<button className='btn'  onClick={(e)=>_return(e)}
									style={{backgroundColor:'#4966B1',color:'white',height:50,width:150}}>
									Anterior
								</button>
							</div>	
						</div>
					</div>  
				</div>
			</form>						
		</div>
	   
	);
};

export default StepFive;
