import React,{ useState, useContext } from 'react' 
import { axios } from '../../config'
import { UserContext } from '../../UserContext';
import { Link, useHistory } from "react-router-dom";

const Login = () =>{

        const history = useHistory()
	const [data,setData] = useState({})
	const { setUser, user, auth, setAuth }=useContext(UserContext);

	const _logan=(e)=>{

		e.preventDefault()

		axios.post('/api/login',data).then(r=>{
			setUser(r.data.user)
			localStorage.setItem('access_token', 'Bearer '+r.data.access_token);
			axios.defaults.headers.common['Authorization'] = 'Bearer '+r.data.access_token
			setAuth(true)
			setData({})
                        history.push('/')
		}).catch(r=>alert(r))
	}

	return (
		<div style={{display:'flex', justifyContent:'center', alignItems:'center',height:'100vh'}}>
			<div className="card center" style={{maxWidth:380}}>
				<div className="card-content black-text">
					<span className="card-title">Login</span>
					<div className="row">
						<form onSubmit={_logan}className="col s12">
							<div className="row">
								<div className="input-field col s12">
									<i className="material-icons prefix">account_circle</i>
									<input onChange={(e)=>setData({...data,email:e.target.value})} value={data.email||''} id="icon_prefix" type="text" className="validate"/>
									<label htmlFor="icon_prefix">Correo</label>
								</div>
								<div className="input-field col s12">
									<i className="material-icons prefix">lock</i>
									<input onChange={(e)=>setData({...data,password:e.target.value})} value={data.password||''} id="password" type="password" className="validate"/>
									<label htmlFor="password">Contraseña</label>
								</div>
							</div>
							<button className="btn waves-effect waves-light grey lighten-1" type="submit" name="action">Entrar<i className="material-icons right">send</i></button>
						</form>
					</div>
				</div>
				{/*<div className="card-action">
					<Link to="/create/">Crear Cuenta</Link>
				</div>
				*/}
			</div>
		</div>
	)
}
export default Login
