import React, { useEffect, useState } from 'react';
import M from 'materialize-css'
import { axios } from '../../config'

const Cupones = () => {

	const [data, setData] = useState({cupon:'', tipo:'', descuento:'', nota:'', activo:true, visible:true});
	const [cupones, setCupones] = useState([])
	const [cupon, setCupon] = useState({cupon:'', tipo:'', descuento:'', nota:'', activo:'', visible:''})

	useEffect(()=>{
		M.Modal.init(document.querySelectorAll('.modal'), {});
		M.Datepicker.init(document.querySelectorAll('.datepicker-cupon'), {format:'yyyy-mm-dd',autoClose:false,i18n:{
			cancel:'cancelar',
			months:['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
			monthsShort: [ 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic' ],
			weekdays: [ 'Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado' ],
			weekdaysShort: [ 'Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab' ],
			weekdaysAbbrev:	['D','L','M','M','J','V','S'],
		}});

		axios.get('/api/cupon')
		.then(r=>{
			setCupones(r.data)
		})
		.catch(r=>alert(r))
	},[])

	const _pushStore = (e) => {
		e.preventDefault()
		const inicio_temporada = document.querySelector('#inicio-temporada').value+' 00:00:00'
		const fin_temporada = document.querySelector('#fin-temporada').value+' 23:59:59'
		axios.post('/api/cupon', {...data, inicio_temporada, fin_temporada})
		.then(r=>{
			setCupones([...cupones, r.data])
			setData({...data, cupon:'', descuento:'', nota:''})
			document.querySelector('#inicio-temporada').value=''
			document.querySelector('#fin-temporada').value=''
			M.toast({html:'Cupón agregado!'})
			M.Modal.init(document.querySelectorAll('.modal'), {});
		})
		.catch(r=>alert(r))
	}

	const _pushEdit = (e) => {
		e.preventDefault()
		const inicio_temporada = document.querySelector('#inicio-temporada-edit').value.split(' ')[0]+' 00:00:00'
		const fin_temporada = document.querySelector('#fin-temporada-edit').value.split(' ')[0]+' 23:59:59'

		axios.put('/api/cupon', {...cupon, inicio_temporada, fin_temporada})
		.then(r=>{
			M.toast({html:'cupon actualizado!'})
			setCupones(cupones.map(f=>f.id===r.data.id?r.data:f))
		})
		.catch(r=>alert(r))
	}

	const _showEdit = (obj) => {
		const instance = M.Modal.getInstance(document.querySelector('#modal-edit'));

		document.querySelector('#inicio-temporada-edit').value=obj.inicio_temporada
		document.querySelector('#fin-temporada-edit').value=obj.fin_temporada

		setCupon(obj)
		console.log(obj)
		instance.open();

		setTimeout(()=>{
			M.updateTextFields()
		},1000)

	}
	const _delete = (cupon) => {
		if(window.confirm('¿Estas seguro de borrar este cupón?'))
			axios.delete(`/api/cupon/${cupon.id}`)
			.then((r) => {
				setCupones(cupones.filter(c=>c.id!==cupon.id))
				M.toast({html:'Cupon borrado correctamente!'})
			})
			.catch(r=>alert(r))
	}

	const _updateActivo = (obj,checked) =>{
		const newCupon = cupones.map(c=>c.id===obj.id?{...obj,activo:checked}:c)
		setCupones(newCupon)
		axios.post('/api/cuponactivo',{id:obj.id,activo:checked})
		.then((r) => {
			M.toast({html:'Actualizado!'})
			setCupon({...cupon, activo:parseInt(r.data.activo)})
			setCupones(cupones.map(c=>c.id===obj.id?r.data:c))
		})
		.catch(r=>alert(r))	
	}
	

	return (
		<>
		<div id='modal1' className='modal'>
			<div className='modal-content'>
				<h4>Nuevo Cupón</h4>
				<form onSubmit={_pushStore}>
					<div className='row'>
						<div className='input-field col s12'>
							<div className='col m6 row'>
								<div className='input-field col s12'>
									<div>Tipo de descuento</div>
									<div className='row'>
									<div className='col'>
										<label onClick={()=>setData({...data, tipo:'%'})}>
											<input name="group1" type="radio" required/>
											<span>%</span>
										</label>
									</div>
									<div className='col'>
										<label onClick={()=>setData({...data, tipo:'$'})}>
											<input name="group1" type="radio" required/>
											<span>$</span>
										</label>
									</div>
									</div>
								</div>
							</div>
							<div className='col m6 row'>
								<div>Cupón:</div>
								<div className="col switch">
									<label>
										Visible
										<input type="checkbox" checked={data.visible} onChange={(e)=>setData({...data, visible:e.target.checked})}/>
										<span className="lever"></span>
										Invisible
									</label>
								</div>
							</div>
						</div>
						<div className='input-field col s12'>
							<input value={data.cupon} onChange={(e)=>setData({...data, cupon:e.target.value})} id='cupon' type='text' className='validate' required/>
							<label htmlFor='cupon'>Cupón</label>
						</div>
						<div className='input-field col s12'>
							<input value={data.descuento} onChange={(e)=>setData({...data, descuento:data.tipo==='%'?(e.target.value>100?100:e.target.value):e.target.value})} type='number' id='descuento' className='validate' required/>
							<label htmlFor='descuento'>Descuento</label>
						</div>
						<div className='col s12'>
							<label htmlFor='inicio-temporada'>Inicio de temporada</label>
							<input type='text' id='inicio-temporada' className='validate datepicker-cupon' required/>
						</div>
						<div className='col s12'>
							<label htmlFor='fin-temporada'>Fin de temporada</label>
							<input type='text' id='fin-temporada' className='validate datepicker-cupon' required/>
						</div>
						<div className='input-field col s12'>
							<input value={data.nota} onChange={(e)=>setData({...data, nota:e.target.value})} type='text' id='nota' className='validate' required/>
							<label htmlFor='nota'>Nota</label>
						</div>
					</div>
					<button className='btn waves-effect waves-light'>Agregar cupon</button>
				</form>
			</div>
		</div>
		<div id='modal-edit' className='modal'>
			<div className='modal-content'>
				<h4>Editar del cupon</h4>
				<form onSubmit={_pushEdit}>
					<div className='row'>
						<div className='input-field col s12'>
							<div className='col m6 row'>
								<div>Tipo</div>
								<div className='row'>
									<div className='col'>
										<label>
											<input name="group1" type="radio" required checked={cupon.tipo==='%'?true:false} onChange={()=>setCupon({...cupon, tipo:'%'})}/>
											<span>%</span>
										</label>
									</div>
									<div className='col'>
										<label>
											<input name="group1" type="radio" required checked={cupon.tipo==='$'?true:false} onChange={()=>setCupon({...cupon, tipo:'$'})}/>
											<span>$</span>
										</label>
									</div>
								</div>
							</div>
							<div className='col m6 row'>
								<div>Cupon:</div>
								<div className="col s8 switch">
									<label>
										No-Visible
										<input type="checkbox" checked={cupon.visible} 
										name="cuponVisto"
										onChange={(e)=>setCupon({...cupon, visible:e.target.checked})}/>
										<span className="lever"></span>
										Visible
									</label>
								</div>
								<div className="col s4" style={{textAlign:'center'}}>
									<a href='#!'  onClick={()=>_delete(cupon)} className='black-text' style={{textAlign:'center'}}>
										<i className="material-icons">delete</i>
									</a>
								</div>
							</div>
						</div>
						<div className='input-field col s12'>
							<input value={cupon.cupon} onChange={(e)=>setCupon({...cupon, cupon:e.target.value})} id='cupon' type='text' className='validate' required/>
							<label htmlFor='cupon'>Cupón</label>
						</div>
						<div className='input-field col s12'>
							<input value={cupon.descuento} onChange={(e)=>setCupon({...cupon, descuento:cupon.tipo==='%'?(e.target.value>100?100:e.target.value):e.target.value})} type='number' id='descuento' className='validate' required/>
							<label htmlFor='descuento'>Descuento</label>
						</div>
						<div className='col s12'>
							<label htmlFor='inicio-temporada-edit'>Inicio Temporada</label>
							<input type='text' id='inicio-temporada-edit' className='validate datepicker-cupon' required/>
						</div>
						<div className='col s12'>
							<label htmlFor='fin-temporada-edit'>Fin Temporada</label>
							<input type='text' id='fin-temporada-edit' className='validate datepicker-cupon' required/>
						</div>
						<div className='input-field col s12'>
							<input value={cupon.nota} onChange={(e)=>setCupon({...cupon, nota:e.target.value})} type='text' id='nota' className='validate'/>
							<label htmlFor='nota'>Nota</label>
						</div>
					</div>
					<button className='modal-close btn waves-effect waves-light'>Guardar</button>
				</form>
			</div>
		</div>
		<div className='card-panel'>
			<h4>Cupones</h4>
			<a className='waves-effect waves-light btn modal-trigger right' href='#modal1'>Agregar</a>
			<table>
				<thead>
					<tr>
						<th>#</th>
						<th>Cupón</th>
						<th>Tipo</th>
						<th>Descuento</th>
						<th>Inicio de temporada</th>
						<th>Fin de temporada</th>
						<th>Nota</th>
						<th>Activo</th>
						<th style={{textAlign:'center'}}>Opciones</th>
					</tr>
				</thead>
				<tbody>
				{cupones.map((f,i)=>
					<tr key={i}>
						<td>{i+1}</td>
						<td>{f.cupon}</td>
						<td><p>{f.tipo}</p></td>
						<td><p>{f.descuento}</p></td>
						<td><p>{f.inicio_temporada}</p></td>
						<td><p>{f.fin_temporada}</p></td>
						<td><p>{f.nota}</p></td>
						<td>
							<div className="switch">
								<label>
									<input type="checkbox" checked={f.activo} 
									onChange={(e)=>_updateActivo(f,e.target.checked)}/>
									<span className="lever"></span>
								</label>
							</div>
						</td>
						<td>
							<div className="col s6" style={{textAlign:'center'}}>
								<a href='#!' onClick={()=>_showEdit(f)} className='black-text' style={{textAlign:'center'}}>
									<i className="material-icons">edit</i>
								</a>
							</div>
							<div className="col s6" style={{textAlign:'center'}}>
								<a href='#!'  onClick={()=>_delete(f)} className='black-text' style={{textAlign:'center'}}>
									<i className="material-icons">delete</i>
								</a>
							</div>

						</td>
					</tr>
				)}
				</tbody>
			</table>

		</div>
		</>
	)
}

export default Cupones
